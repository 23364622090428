import { useLocation } from "react-router-dom";
import useAuth from "./UseAuth";
import StateContainer from "./components/StateContainer";
import RoutesSite from "./Routes";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

function App() {
  const history = useLocation();
  useAuth(history);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      <Navigate to='/login' />;
    }
  });

  return (
    <StateContainer>
      <div className='App'>
        <RoutesSite />
      </div>
    </StateContainer>
  );
}

export default App;
