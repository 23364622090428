import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import useAuth from "../UseAuth";
import { StateContext } from "../components/StateContainer";
import { Link, useParams } from "react-router-dom";
import Logout from "./components/logout/Logout";
import { SiSessionize as IconSessione } from "react-icons/si";
import { IoMdMail as IconMail } from "react-icons/io";

import Moment from "react-moment";

function AnagraficaCompletaIscrizione() {
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    eventCode,
    autologinCode,
    AllUserData,
    token,
    dettagliEvento,
  } = useContext(StateContext);

  console.log(AllUserData);
  const { idSessione } = useParams();

  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  const [mezzoScelto, setMezzoScelto] = useState("");
  const [dettagliPartecipazione, setDettagliPartecipazione] = useState([]);

  useEffect(() => {
    setPageTitle("Anagrafica completa");
  }, []);

  useEffect(() => {
    console.log(eventCode);
    console.log(autologinCode);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/anagrafica-completa/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        console.log(response.data.response);
        setDettagliPartecipazione(response.data.response);
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  }, []);

  const AggiornaDatiMezziTrasporto = () => {
    console.log(eventCode);
    console.log(autologinCode);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/update`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
        datiPartecipazione: dettagliPartecipazione,
      },
    })
      .then((response) => {
        console.log(response.data.response);
        window.location.replace(
          `/sessioni/${idSessione}/accomodation?eventCode=${eventCode}&autologinCode=${autologinCode}`
        );
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };

  const sezioniPartecipazione = [
    {
      id: 1,
      label: "Anagrafica",
      input: "anagrafica",
      data: dettagliPartecipazione.anagrafica,
    },
    {
      id: 2,
      label: "Documenti",
      input: "documenti",
      data: dettagliPartecipazione.documenti,
    },
    {
      id: 3,
      label: "Trasporti",
      input: "trasporti",
      data: dettagliPartecipazione.trasporti,
    },
    {
      id: 4,
      label: "Accomodation",
      input: "accomodation",
      data: dettagliPartecipazione.accomodation,
    },
    {
      id: 5,
      label: "Note",
      input: "note",
      data: dettagliPartecipazione.note,
    },
  ];

  console.log(dettagliPartecipazione.anagrafica);
  return (
    <div
      className="account-pages bg-grey-stellantis"
      style={{
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <div className="container">
        <div
          className="row"
          style={{
            // minHeight: "100vh",
            height: "auto",
          }}
        >
          <div
            className="col-12 p-0"
            style={{ height: "100px", marginBottom: "60px" }}
          >
            <div className="box-settings-sessioni position-relative">
              <Logout />
              <img src={`${dettagliEvento.logoEvento}`} alt="" />
              <div className="container-nome-evento-sessioni">
                <div className="box-nome-evento-anagrafica-completa">
                  {dettagliEvento.nomeEvento}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" style={{ marginBottom: "30px" }}>
            <div className="card p-3 ">
              <h3 className="text-blue-stellantis fw-bold">
                {AllUserData.firstname || ""} {AllUserData.lastname || ""}
              </h3>
              <p className="text-blue-stellantis font-size-18 fw-bold">
                {AllUserData.ragioneSociale || ""}
              </p>
              <h4 className="text-blue-stellantis fw-bold my-4 d-flex align-items-center gap-2">
                <IconSessione />{" "}
                {dettagliPartecipazione.nome_sessione !== ""
                  ? dettagliPartecipazione.nome_sessione
                  : `Sessione ${dettagliPartecipazione.sessioneId}`}{" "}
                -{" "}
                <span className="fw-normal">
                  <Moment format="DD-MM-YYYY">
                    {dettagliPartecipazione.data_sessione}
                  </Moment>
                </span>
              </h4>
            </div>
          </div>

          <div className="col-12">
            <div className="accordion" id="accordionDatiPartecipazione">
              {sezioniPartecipazione.map((sezione) => {
                return (
                  <div
                    class="accordion-item mb-3 border-0"
                    style={{ borderRadius: "20px" }}
                  >
                    <h2 class="accordion-header" id={`${sezione.id}`}>
                      <button
                        class="accordion-button accordion-button-stellantis collapsed py-4"
                        style={{ borderRadius: "20px" }}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${sezione.input}`}
                        aria-expanded="false"
                        aria-controls={`${sezione.input}`}
                      >
                        <b>{sezione.label}</b>
                      </button>
                    </h2>
                    <div
                      id={`${sezione.input}`}
                      class="accordion-collapse collapse"
                      aria-labelledby={`${sezione.input}`}
                      data-bs-parent="#accordionDatiPartecipazione"
                    >
                      <div class="accordion-body">
                        {sezione &&
                          sezione.data &&
                          Object.entries(sezione.data).map(([key, value]) => {
                            return (
                              <p key={key} className="py-1">
                                <b>{key}</b>:{" "}
                                <span className="text-capitalize">{value}</span>
                              </p>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="col-12">
            <div className="my-3 text-end d-flex align-items-center justify-content-between">
              <Link
                to={`/qr-code?eventCode=${eventCode}&autologinCode=${autologinCode}`}
                className="btn bg-blue-stellantis w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                type="submit"
              >
                Indietro
              </Link>
              <a
                // to={"/accomodation"}
                href="mailto:nobody@test.com"
                className="btn bg-white w-sm waves-effect waves-light w-100 me-2 p-0 d-flex justify-content-between"
                type="submit"
                style={{ height: "60px" }}
              >
                <span className="text-black d-flex align-items-center font-size-11 text-start px-2">
                  <b>Contatta il servizio per cambio dati</b>
                </span>
                <span
                  className="bg-blue-stellantis d-flex align-items-center justify-content-center fs-3 text-white"
                  style={{ minWidth: "60px" }}
                >
                  <IconMail />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnagraficaCompletaIscrizione;
