import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import useAuth from "../UseAuth";
import { StateContext } from "../components/StateContainer";
import { Link, useParams } from "react-router-dom";
import Logout from "./components/logout/Logout";
import { SiSessionize as IconSessione } from "react-icons/si";
import { IoMdMail as IconMail } from "react-icons/io";

import Moment from "react-moment";

function AnagraficaPartecipanteHostess() {
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    eventCode,
    autologinCode,
    AllUserData,
    token,
    dettagliEvento,
  } = useContext(StateContext);

  const { idSessione, idPartecipante } = useParams();

  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  const [mezzoScelto, setMezzoScelto] = useState("");
  const [datiAggiornati, setDatiAggiornati] = useState(false);
  const [dettagliPartecipazione, setDettagliPartecipazione] = useState([]);

  useEffect(() => {
    setPageTitle("Anagrafica completa");
  }, []);

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/anagrafica-completa-partecipante-hostess/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        idPartecipante: idPartecipante,
      },
    })
      .then((response) => {
        setDettagliPartecipazione(response.data.response);
        console.log(response.data.response.anagrafica);
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  }, [datiAggiornati]);

  const AggiornaDatiPartecipazioneUtente = () => {
    setDatiAggiornati(false);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/hostess/partecipazione/update`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        datiPartecipazione: dettagliPartecipazione,
        idPartecipante: idPartecipante,
      },
    })
      .then((response) => {
        // window.location.replace(
        //   `/sessioni/${idSessione}/accomodation?eventCode=${eventCode}&autologinCode=${autologinCode}`
        // );
        console.log(response);
        setDatiAggiornati(true);
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };

  const handleChange = (e, key) => {
    const { id, value } = e.target;
    setDettagliPartecipazione((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        [id]: value,
      },
    }));
  };

  return (
    <div
      className="account-pages bg-grey-stellantis"
      style={{
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <div className="container">
        <div
          className="row"
          style={{
            // minHeight: "100vh",
            height: "auto",
          }}
        >
          <div
            className="col-12 p-0"
            style={{ height: "100px", marginBottom: "60px" }}
          >
            <div className="box-settings-sessioni position-relative">
              <Logout />
              <img src={`${dettagliEvento.logoEvento}`} alt="" />
              <div className="container-nome-evento-sessioni">
                <div className="box-nome-evento-anagrafica-completa">
                  {dettagliEvento.nomeEvento}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" style={{ marginBottom: "30px" }}>
            <div className="card p-3 ">
              <h3 className="text-blue-stellantis fw-bold">
                {dettagliPartecipazione && dettagliPartecipazione.anagrafica
                  ? dettagliPartecipazione.anagrafica.nome
                  : ""}{" "}
                {dettagliPartecipazione && dettagliPartecipazione.anagrafica
                  ? dettagliPartecipazione.anagrafica.cognome
                  : ""}
              </h3>
              <p className="text-blue-stellantis font-size-18 fw-bold">
                {dettagliPartecipazione && dettagliPartecipazione.anagrafica
                  ? dettagliPartecipazione.anagrafica?.["ragioneSociale"]
                  : ""}
              </p>
              <div className=" my-3 p-3" style={{ border: "1px black solid" }}>
                <h4 className="text-blue-stellantis fw-bold d-flex align-items-center gap-2 m-0">
                  <IconSessione />{" "}
                  {dettagliPartecipazione.nome_sessione !== ""
                    ? dettagliPartecipazione.nome_sessione
                    : `Sessione ${dettagliPartecipazione.sessioneId}`}{" "}
                  -{" "}
                  <span className="fw-normal">
                    <Moment format="DD-MM-YYYY">
                      {dettagliPartecipazione.data_sessione}
                    </Moment>
                  </span>
                </h4>
              </div>
            </div>
          </div>

          {/* Accordion anagrafica partecipante */}
          <div className="col-12">
            <div className="accordion" id="accordionDatiPartecipazione">
              {dettagliPartecipazione && (
                <div
                  className="accordion-item mb-3 border-0"
                  style={{ borderRadius: "20px" }}
                >
                  <h2
                    className="accordion-header"
                    id={`heading-${dettagliPartecipazione.id}`}
                  >
                    <button
                      className="accordion-button accordion-button-stellantis collapsed py-4"
                      style={{ borderRadius: "20px" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#anagrafica-${dettagliPartecipazione.id}`}
                      aria-expanded="false"
                      aria-controls={`anagrafica-${dettagliPartecipazione.id}`}
                    >
                      <b>Anagrafica</b>
                    </button>
                  </h2>
                  <div
                    id={`anagrafica-${dettagliPartecipazione.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading-${dettagliPartecipazione.id}`}
                    data-bs-parent="#accordionDatiPartecipazione"
                  >
                    <div className="accordion-body">
                      <p className="mb-1">
                        <b>Nome:</b>{" "}
                        {dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.nome}
                      </p>
                      <p className="mb-1">
                        <b>Cognome:</b>{" "}
                        {dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.cognome}
                      </p>
                      <p className="mb-1">
                        <b>Email:</b>{" "}
                        {dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.email}
                      </p>
                      <p className="mb-1">
                        <b>Ragione Sociale:</b>{" "}
                        {dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.ragioneSociale}
                      </p>
                      <p className="mb-1">
                        <b>Città:</b>{" "}
                        {dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.citta}
                      </p>
                      <p className="mb-1">
                        <b>Provincia:</b>{" "}
                        {dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.provincia}
                      </p>
                      <p className="mb-1">
                        <b>Id esterno:</b>{" "}
                        {dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.idEsterno}
                      </p>
                      <p className="mb-1">
                        <b>Codice concessionaria:</b>{" "}
                        {dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica
                            .codiceConcessionaria}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {/* Accordion documenti */}

              {dettagliPartecipazione && (
                <div
                  className="accordion-item mb-3 border-0"
                  style={{ borderRadius: "20px" }}
                >
                  <h2
                    className="accordion-header"
                    id={`heading-${dettagliPartecipazione.id}`}
                  >
                    <button
                      className="accordion-button accordion-button-stellantis collapsed py-4"
                      style={{ borderRadius: "20px" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#documenti-${dettagliPartecipazione.id}`}
                      aria-expanded="false"
                      aria-controls={`documenti-${dettagliPartecipazione.id}`}
                    >
                      <b>Documenti</b>
                    </button>
                  </h2>
                  <div
                    id={`documenti-${dettagliPartecipazione.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading-${dettagliPartecipazione.id}`}
                    data-bs-parent="#accordionDatiPartecipazione"
                  >
                    <div className="accordion-body">
                      <p className="mb-1">
                        <b>Codice carta d'identità:</b>{" "}
                        {dettagliPartecipazione.documenti &&
                          dettagliPartecipazione.documenti.codiceCartaIdentita}
                      </p>
                      <p className="mb-1">
                        <b>Scadenza carta d'identita:</b>{" "}
                        {dettagliPartecipazione.documenti &&
                          dettagliPartecipazione.documenti
                            .scadenzaCartaIdentita}
                      </p>
                      <p className="mb-1">
                        <b>Codice Patente:</b>{" "}
                        {dettagliPartecipazione.documenti &&
                          dettagliPartecipazione.documenti.codicePatente}
                      </p>
                      <p className="mb-1">
                        <b>Scadenza Patente:</b>{" "}
                        {dettagliPartecipazione.documenti &&
                          dettagliPartecipazione.documenti.scadenzaPatente}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {/* Accordion trasporti */}

              {dettagliPartecipazione && (
                <div
                  className="accordion-item mb-3 border-0"
                  style={{ borderRadius: "20px" }}
                >
                  <h2
                    className="accordion-header"
                    id={`heading-${dettagliPartecipazione.id}`}
                  >
                    <button
                      className="accordion-button accordion-button-stellantis collapsed py-4"
                      style={{ borderRadius: "20px" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#trasporti-${dettagliPartecipazione.id}`}
                      aria-expanded="false"
                      aria-controls={`trasporti-${dettagliPartecipazione.id}`}
                    >
                      <b>Trasporti</b>
                    </button>
                  </h2>
                  <div
                    id={`trasporti-${dettagliPartecipazione.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading-${dettagliPartecipazione.id}`}
                    data-bs-parent="#accordionDatiPartecipazione"
                  >
                    <div className="accordion-body">
                      <div className="mb-3">
                        <label
                          className="form-label text-black mt-2"
                          htmlFor="mezzo"
                        >
                          Mezzo di trasporto
                        </label>
                        <select
                          className="form-select mb-1"
                          aria-label="Mezzo"
                          id="mezzo"
                          value={
                            dettagliPartecipazione.trasporti &&
                            dettagliPartecipazione.trasporti.mezzo
                          }
                          onChange={(e) => handleChange(e, "trasporti")}
                        >
                          <option value="aereo">Aereo</option>
                          <option value="treno">Treno</option>
                          <option value="macchina">Auto</option>
                        </select>

                        <label
                          className="form-label mt-3"
                          htmlFor="nVoloAndata"
                        >
                          Numero volo di andata
                        </label>
                        <input
                          type="text"
                          className="form-control mb-1"
                          id="nVoloAndata"
                          placeholder="Inserisci numero volo andata"
                          value={
                            dettagliPartecipazione.trasporti &&
                            dettagliPartecipazione.trasporti.nVoloAndata
                          }
                          onChange={(e) => handleChange(e, "trasporti")}
                        />
                        <label
                          className="form-label mt-3"
                          htmlFor="oraArrivoAndata"
                        >
                          Ora di arrivo andata
                        </label>
                        <input
                          type="time"
                          className="form-control mb-1"
                          id="oraArrivoAndata"
                          placeholder="Inserisci ora di arrivo andata"
                          value={
                            dettagliPartecipazione.trasporti &&
                            dettagliPartecipazione.trasporti.oraArrivoAndata
                          }
                          onChange={(e) => handleChange(e, "trasporti")}
                        />

                        <label
                          className="form-label mt-3"
                          htmlFor="nVoloAndata"
                        >
                          Numero volo di ritorno
                        </label>
                        <input
                          type="text"
                          className="form-control mb-1"
                          id="nVoloRitorno"
                          placeholder="Inserisci numero volo ritorno"
                          value={
                            dettagliPartecipazione.trasporti &&
                            dettagliPartecipazione.trasporti.nVoloRitorno
                          }
                          onChange={(e) => handleChange(e, "trasporti")}
                        />

                        <label
                          className="form-label mt-3"
                          htmlFor="oraPartenzaRitorno"
                        >
                          Ora di partenza ritorno
                        </label>
                        <input
                          type="time"
                          className="form-control mb-1"
                          id="oraPartenzaRitorno"
                          placeholder="Inserisci ora di partenza ritorno"
                          value={
                            dettagliPartecipazione.trasporti &&
                            dettagliPartecipazione.trasporti.oraPartenzaRitorno
                          }
                          onChange={(e) => handleChange(e, "trasporti")}
                        />
                        <button
                          className="btn bg-blue-stellantis w-sm waves-effect waves-light w-100 text-white mt-4 py-3"
                          onClick={AggiornaDatiPartecipazioneUtente}
                        >
                          Aggiorna dati
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Accordion accomodation */}

              {dettagliPartecipazione && (
                <div
                  className="accordion-item mb-3 border-0"
                  style={{ borderRadius: "20px" }}
                >
                  <h2
                    className="accordion-header"
                    id={`heading-${dettagliPartecipazione.id}`}
                  >
                    <button
                      className="accordion-button accordion-button-stellantis collapsed py-4"
                      style={{ borderRadius: "20px" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#accomodation-${dettagliPartecipazione.id}`}
                      aria-expanded="false"
                      aria-controls={`accomodation-${dettagliPartecipazione.id}`}
                    >
                      <b>Accomodation</b>
                    </button>
                  </h2>
                  <div
                    id={`accomodation-${dettagliPartecipazione.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading-${dettagliPartecipazione.id}`}
                    data-bs-parent="#accordionDatiPartecipazione"
                  >
                    <div className="accordion-body">
                      <div className="mb-3">
                        <div className="form-check my-2">
                          <label className="form-check-label" htmlFor="cena">
                            Cena
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="cena"
                            checked={
                              dettagliPartecipazione.accomodation
                                ? dettagliPartecipazione.accomodation.cena
                                : false
                            }
                            onChange={(e) => {
                              const { id, checked } = e.target;
                              setDettagliPartecipazione((prevState) => ({
                                ...prevState,
                                accomodation: {
                                  ...prevState.accomodation,
                                  [id]: checked,
                                },
                              }));
                            }}
                          />
                        </div>

                        <div className="form-check mb-2">
                          <label
                            className="form-check-label"
                            htmlFor="pernottamento"
                          >
                            Pernottamento
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="pernottamento"
                            checked={
                              dettagliPartecipazione.accomodation
                                ? dettagliPartecipazione.accomodation
                                    .pernottamento
                                : false
                            }
                            onChange={(e) => {
                              const { id, checked } = e.target;
                              setDettagliPartecipazione((prevState) => ({
                                ...prevState,
                                accomodation: {
                                  ...prevState.accomodation,
                                  [id]: checked,
                                },
                              }));
                            }}
                          />
                        </div>

                        <label className="form-label" htmlFor="intolleranze">
                          Intolleranze
                        </label>
                        <textarea
                          type="text"
                          className="form-control mb-1"
                          id="intolleranze"
                          placeholder="Inserisci intolleranze"
                          value={
                            dettagliPartecipazione.accomodation &&
                            dettagliPartecipazione.accomodation.intolleranze
                          }
                          onChange={(e) => handleChange(e, "accomodation")}
                        ></textarea>

                        <button
                          className="btn bg-blue-stellantis w-sm waves-effect waves-light w-100 text-white mt-4 py-3"
                          onClick={AggiornaDatiPartecipazioneUtente}
                        >
                          Aggiorna dati
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Accordion note generali  */}
              {dettagliPartecipazione && (
                <div
                  className="accordion-item mb-3 border-0"
                  style={{ borderRadius: "20px" }}
                >
                  <h2
                    className="accordion-header"
                    id={`heading-${dettagliPartecipazione.id}`}
                  >
                    <button
                      className="accordion-button accordion-button-stellantis collapsed py-4"
                      style={{ borderRadius: "20px" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#note-${dettagliPartecipazione.id}`}
                      aria-expanded="false"
                      aria-controls={`note-${dettagliPartecipazione.id}`}
                    >
                      <b>Note</b>
                    </button>
                  </h2>
                  <div
                    id={`note-${dettagliPartecipazione.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading-${dettagliPartecipazione.id}`}
                    data-bs-parent="#accordionDatiPartecipazione"
                  >
                    <div className="accordion-body">
                      <p className="mb-1">
                        <b>Note:</b>{" "}
                        {dettagliPartecipazione.note &&
                          dettagliPartecipazione.note.note}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Accordion Anagrafica Partecipante  */}

          <div className="col-12">
            <div className="my-3 text-end d-flex align-items-center justify-content-between">
              <Link
                to={`/dashboard?eventCode=${eventCode}`}
                className="btn bg-blue-stellantis w-sm waves-effect waves-light w-100 text-white py-3"
                type="submit"
              >
                Indietro
              </Link>
              {/* <a
                // to={"/accomodation"}
                href="mailto:nobody@test.com"
                className="btn bg-white w-sm waves-effect waves-light w-100 me-2 p-0 d-flex justify-content-between"
                type="submit"
                style={{ height: "60px" }}
              >
                <span className="text-black d-flex align-items-center font-size-11 text-start px-2">
                  <b>Contatta il servizio per cambio dati</b>
                </span>
                <span
                  className="bg-blue-stellantis d-flex align-items-center justify-content-center fs-3 text-white"
                  style={{ minWidth: "60px" }}
                >
                  <IconMail />
                </span>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnagraficaPartecipanteHostess;
