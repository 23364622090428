import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import useAuth from "../UseAuth";
import { StateContext } from "../components/StateContainer";
import { Link } from "react-router-dom";
function Registrazione() {
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    dettagliEvento,
    eventCode,
    autologinCode,
  } = useContext(StateContext);

  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  useEffect(() => {
    setPageTitle("Login");
  }, []);

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_URL}/user/registrazione/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        console.log(response);
        setEmail(response.data.response.email);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const CreaNuovoUser = (event) => {
    setIsError("");
    event.preventDefault();
    setLoading(true);
    const loginPayload = {
      email: email,
      password: password,
      eventCode: eventCode,
      autologinCode: autologinCode,
    };

    axios
      .post(
        `${process.env.REACT_APP_PUBLIC_URL}/user/registrazione`,
        loginPayload
      )
      .then(
        (response) => {
          setLoading(false);
          setIsError("");
          if (response.data && response.data.error) {
            setIsError(response.data.error);
          } else {
            const token = response.data.response.token;
            login(token);
          }
        },
        (reason) => {
          setLoading(false);
          setIsError(true);
          console.log("reason");
          console.error(reason);
          if (reason.response.data.code === 401) {
            setIsError("Email o password errati");
          } else {
            setIsError("C'è stato un errore nel server. Riprovare più tardi.");
          }
        }
      );
  };

  return (
    <div
      className="account-pages bg-blue-stellantis"
      style={{
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <div className="container">
        <div
          className="row"
          style={{
            minHeight: "100vh",
            height: "auto",
          }}
        >
          <div className="col-12 p-0">
            <div className="box-settings">
            <img
              src={`${(dettagliEvento && dettagliEvento.logoEvento) || ""}`}
              alt=""
              className="mt-2 logo-settings"
            />
            </div>
          </div>
          <div className="col-12 p-0 text-center mt-5">
            <h1
              className="text-center text-white mb-5"
              style={{ fontWeight: "lighter", fontSize: "25pt" }}
            >
              {dettagliEvento.nomeEvento || "-"}
            </h1>
            <p
              className="text-start text-white p-2"
              style={{ fontSize: "12pt" }}
            >
              Benvenuto/a <b>{email}</b>! Per completare la registrazione,
              inserisci una password che ti servirà per accedere.
            </p>
          </div>
          <div className="col-12">
            <div className="bg-blue-stellantis mb-5">
              <form onSubmit={CreaNuovoUser}>
                {/* <div className="mb-5">
                  <label className="form-label text-white" htmlFor="username">
                    E-mail
                  </label>
                  <p className="text-white mt-4">{email}</p>
                </div> */}

                <div className="" style={{ marginBottom: "73px" }}>
                  <label
                    className="form-label text-white mt-4"
                    htmlFor="userpassword"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control bg-transparent input-login-stellantis mt-4 text-white"
                    id="userpassword"
                    placeholder="Inserisci la password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="mt-5 text-end d-flex align-items-center justify-content-between">
                  <button
                    className="btn bg-white w-sm waves-effect waves-light w-100 text-blue-stellantis ms-2 py-3"
                    type="submit"
                  >
                    Crea Account
                  </button>
                </div>
                {isError && (
                  <div
                    class="alert alert-danger text-black mt-3 text-center"
                    role="alert"
                  >
                    {isError}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registrazione;
