import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import useAuth from "../UseAuth";
import { StateContext } from "../components/StateContainer";
import { Link, useParams } from "react-router-dom";
import Logout from "./components/logout/Logout";
import { MdPhotoCamera as Fotocamera } from "react-icons/md";
import { MdOutlineAirplanemodeActive as Plane } from "react-icons/md";
import { MdTrain as Train } from "react-icons/md";
import { MdDirectionsCarFilled as Car } from "react-icons/md";
import { FaHotel as Hotel } from "react-icons/fa";
import { IoMdRestaurant as Ristorante } from "react-icons/io";

function Accomodation() {
  const { idSessione } = useParams();
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    eventCode,
    autologinCode,
    token,
    dettagliEvento,
  } = useContext(StateContext);

  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  const [dettagliAccomodation, setDettagliAccomodation] = useState([]);

  useEffect(() => {
    setPageTitle("Accomodation");
  }, []);

  // Gestore di eventi per l'aggiornamento dei valori degli input
  const handleChange = (key, value) => {
    // Aggiorna lo stato con il nuovo valore per la chiave specificata
    setDettagliAccomodation((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        console.log(dettagliAccomodation);

        setDettagliAccomodation(response.data.response);
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  }, []);

  const AggiornaDatiAccomodation = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/update`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
        datiPartecipazione: dettagliAccomodation,
      },
    })
      .then((response) => {
        window.location.replace(
          `/sessioni/${idSessione}/note-generali?eventCode=${eventCode}&autologinCode=${autologinCode}`
        );
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };

  return (
    <div
      className="account-pages bg-grey-stellantis"
      style={{
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <div className="container">
        <div
          className="row p-2"
          style={{
            // minHeight: "100vh",
            height: "auto",
          }}
        >
          <div
            className="col-12 p-0"
            style={{ height: "100px", marginBottom: "60px" }}
          >
            <div className="box-settings-sessioni position-relative">
              <Logout />
              <img src={`${dettagliEvento.logoEvento}`} alt="" />
              <div className="container-nome-evento-sessioni">
                <div className="box-nome-evento-sessioni">
                  {dettagliEvento.nomeEvento}
                </div>
                <div className="box-caricamento p-0 bg-white">
                  <div
                    className="progress-bar progress-bar-selezione progress-bar-accomodation"
                    role="progressbar"
                    style={{
                      width: "83.5%",
                    }}
                    aria-valuenow="83.5"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" style={{ marginBottom: "30px" }}>
            <h5
              className="text-center"
              style={{ fontSize: "25px", fontWeight: "bold" }}
            >
              Accomodation
            </h5>
            <p className="text-muted text-center">
              In questa sezione può indicare le sue preferenze
              <br />
              relative al pernottamento e al cibo.
            </p>
          </div>

          {/* Pernottamento  */}
          <div
            className="card bg-blue-stellantis mb-4"
            style={{ borderRadius: "10px" }}
          >
            <div className="card-body">
              <h5 className="card-title text-white  d-flex align-items-center gap-3">
                <Hotel style={{ fontSize: "25px" }} />
                <b>Pernottamento</b>
              </h5>
              <div className="d-flex align-items-center justify-content-between gap-4 pt-2">
                <button
                  className={`btn bg-grey-stellantis w-100 rounded-pill ${
                    dettagliAccomodation.pernottamento
                      ? "necessario-transfer-active"
                      : ""
                  }`}
                  style={{ fontSize: "20px" }}
                  onClick={(e) => handleChange("pernottamento", true)}
                >
                  sì
                  {/* necessario-transfer-active */}
                </button>
                <button
                  className={`btn bg-grey-stellantis w-100 rounded-pill  ${
                    !dettagliAccomodation.pernottamento
                      ? "necessario-transfer-active"
                      : ""
                  }`}
                  style={{ fontSize: "20px" }}
                  onClick={(e) => handleChange("pernottamento", false)}
                >
                  no
                </button>
              </div>
            </div>
          </div>

          {/* Cena  */}
          <div
            className="card bg-blue-stellantis mb-4"
            style={{ borderRadius: "10px" }}
          >
            <div className="card-body">
              <h5 className="card-title text-white  d-flex align-items-center gap-3">
                <Ristorante style={{ fontSize: "30px" }} />
                <b>Ristorante</b>
              </h5>
              <div className="d-flex align-items-center justify-content-between gap-4 pt-2">
                <button
                  className={`btn bg-grey-stellantis w-100 rounded-pill  ${
                    dettagliAccomodation.cena
                      ? "necessario-transfer-active"
                      : ""
                  }`}
                  style={{ fontSize: "20px" }}
                  onClick={(e) => handleChange("cena", true)}
                >
                  sì
                  {/* necessario-transfer-active */}
                </button>
                <button
                  className={`btn bg-grey-stellantis w-100 rounded-pill ${
                    !dettagliAccomodation.cena
                      ? "necessario-transfer-active"
                      : ""
                  }`}
                  style={{ fontSize: "20px" }}
                  onClick={(e) => handleChange("cena", false)}
                >
                  no
                </button>
              </div>
              <div
                className={`form-floating my-4  ${
                  !dettagliAccomodation.cena ? "d-none" : ""
                }`}
              >
                <p className="text-white mb-2" style={{ fontSize: "14px" }}>
                  Indicare intollereanze alimentari
                </p>
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  style={{ minHeight: "200px" }}
                  value={dettagliAccomodation.intolleranzeAlimentari}
                  onChange={(e) =>
                    handleChange("intolleranzeAlimentari", e.target.value)
                  }
                ></textarea>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="my-3 text-end d-flex align-items-center justify-content-between">
              <Link
                to={
                  dettagliAccomodation.abilitaTrasporti
                    ? `/sessioni/${idSessione}/mezzi-trasporto?eventCode=${eventCode}&autologinCode=${autologinCode}`
                    : `/sessioni/${idSessione}/caricamento-documenti?eventCode=${eventCode}&autologinCode=${autologinCode}`
                }
                className="btn bg-blue-stellantis w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                type="submit"
              >
                Indietro
              </Link>
              <button
                onClick={AggiornaDatiAccomodation}
                className="btn bg-blue-stellantis w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                type="submit"
              >
                Avanti
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accomodation;
