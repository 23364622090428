import React, { useState, createContext } from "react";
import Cookies from "js-cookie";
import { usePDF } from "react-to-pdf";

export const StateContext = createContext();

const StateContainer = ({ children }) => {
  // PROGETTO BMB

  // Numero progressivo macchina corrente per cliente
  let numeroProgressivoMacchinaCliente = localStorage.getItem(
    "numeroProgressivoMacchinaCliente"
  );

  // Numero progressivo macchina corrente
  let numeroProgressivoCurrentMacchina = localStorage.getItem(
    "numeroProgressivoCurrentMacchina"
  );

  // Numero progressivo unità corrente
  let numeroProgressivoCurrentUnit = localStorage.getItem(
    "numeroProgressivoCurrentUnit"
  );

  const [pageTitle, setPageTitle] = useState("Dashboard");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const token = localStorage.getItem("token");

  let AllUserData = null;
  let idUtenteLoggato = null;
  let ragioneSociale = null;
  let firstName = null;
  let userLastname = null;
  let userEmail = null;
  let userRoles = null;

  //Dati dello User Nei Cookie
  if (Cookies.get("userData")) {
    AllUserData = JSON.parse(Cookies.get("userData"));
    idUtenteLoggato = `${AllUserData.id}`;
    firstName = `${AllUserData.firstname}`;
    ragioneSociale = `${
      AllUserData.ragioneSociale ? AllUserData.ragioneSociale : ""
    }`;
    userLastname = `${AllUserData.lastname}`;
    userEmail = `${AllUserData.email}`;
    userRoles = AllUserData.roles;
  }

  // Dettagli generici uscita cliente
  const [dettagliGenericiUscita, setDettagliGenericiUscita] = useState([]);
  const [telefonoClienteUscita, setTelefonoClienteUscita] = useState(null);
  const [cellulareClienteUscita, setCellulareClienteUscita] = useState(null);
  const [pIvaUscitaClienteUscita, setPIvaUscitaClienteUscita] = useState(null);
  const [cFiscaleClienteUscita, setCFiscaleClienteUscita] = useState(null);
  const [emailClienteUscita, setEmailClienteUscita] = useState(null);

  // Lista Uscita
  const [listaUscite, setListaUscite] = useState([]);

  // Lista Macchine
  const [listaMacchine, setListaMacchine] = useState([]);

  // Dettaglio Macchina
  const [dettaglioMacchina, setDettaglioMacchina] = useState([]);

  // Prendere il Numero Progressivo Unità Esterna
  const [indicatoreNumerazioneMacchina, setIndicatoreNumerazioneMacchina] =
    useState("");

  // Lista Unità
  const [listaUnita, setListaUnita] = useState([]);

  // Campi delle Note del Tecnico per la chiusura dell'uscita

  const [oraInizio, setOraInizio] = useState("");
  const [metodoPagamento, setMetodoPagamento] = useState("");
  const [costoIntervento, setCostoIntervento] = useState("");
  const [cartaPreventivo, setCartaPreventivo] = useState("");
  const [invioInformativa, setInvioInformativa] = useState(false);
  const [noteTecnico, setNoteTecnico] = useState("");
  const [noteTecnicoUfficio, setNoteTecnicoUfficio] = useState("");

  // Form di Ricerca nella Lista delle Macchine
  const [searchMacchina, setSearchMacchina] = useState("");

  // Form Dati di riferimento della macchina per crea e modifica

  // Dati Macchina
  const [fabbricante, setFabbricante] = useState("");
  const [modello, setModello] = useState("");
  const [riferimentoImmobile, setRiferimentoImmobile] = useState("");
  const [matricola, setMatricola] = useState("");
  const [numeroCircuiti, setNumeroCircuiti] = useState("");
  const [tipologia, setTipologia] = useState("");
  const [gas, setGas] = useState("");
  const [quantitativoGasDaTarga, setQuantitativoGasDaTarga] = useState("");
  const [quantitativoGasAggiunto, setQuantitativoGasAggiunto] = useState("");
  const [numeroProgressivo, setNumeroProgressivo] = useState("");

  // Lista Tipologie Macchina
  const [listTipologieMacchina, setListTipologieMacchina] = useState([]);

  // Lista Tipologie Gas Macchina
  const [listTipologieGas, setListTipologieGas] = useState([]);

  // Potenze
  const [potenzaTermicaNominaleTotaleMax, setPotenzaTermicaNominaleTotaleMax] =
    useState("");
  const [
    potenzaFrigoriferaNominaleRaffrescamento,
    setPotenzaFrigoriferaNominaleRaffrescamento,
  ] = useState("");
  const [
    potenzaFrigoriferaNominaleRiscaldamento,
    setPotenzaFrigoriferaNominaleRiscaldamento,
  ] = useState("");
  const [
    potenzaFrigoriferaNominaleAssorbita,
    setPotenzaFrigoriferaNominaleAssorbita,
  ] = useState("");
  const [potenzaTermicaNominaleAssorbita, setPotenzaTermicaNominaleAssorbita] =
    useState("");

  // Caratteristiche
  const [climatizzazioneEstiva, setClimatizzazioneEstiva] = useState(false);

  const [climatizzazioneInvernale, setClimatizzazioneInvernale] =
    useState(false);
  const [produzioneACS, setProduzioneACS] = useState(false);

  // Note
  const [noteMacchina, setNoteMacchina] = useState("");

  // Form Dati di riferimento della macchina per

  // Dati Macchina
  const [fabbricanteUpdate, setFabbricanteUpdate] = useState("");
  const [modelloUpdate, setModelloUpdate] = useState("");
  const [riferimentoImmobileUpdate, setRiferimentoImmobileUpdate] =
    useState("");
  const [matricolaUpdate, setMatricolaUpdate] = useState("");
  const [numeroCircuitiUpdate, setNumeroCircuitiUpdate] = useState("");
  const [tipologiaUpdate, setTipologiaUpdate] = useState("");
  const [gasUpdate, setGasUpdate] = useState("");
  const [quantitativoGasDaTargaUpdate, setQuantitativoGasDaTargaUpdate] =
    useState("");
  const [quantitativoGasAggiuntoUpdate, setQuantitativoGasAggiuntoUpdate] =
    useState("");
  const [numeroProgressivoUpdate, setNumeroProgressivoUpdate] = useState("");

  // Potenze
  const [
    potenzaTermicaNominaleTotaleMaxUpdate,
    setPotenzaTermicaNominaleTotaleMaxUpdate,
  ] = useState("");
  const [
    potenzaFrigoriferaNominaleRaffrescamentoUpdate,
    setPotenzaFrigoriferaNominaleRaffrescamentoUpdate,
  ] = useState("");
  const [
    potenzaFrigoriferaNominaleRiscaldamentoUpdate,
    setPotenzaFrigoriferaNominaleRiscaldamentoUpdate,
  ] = useState("");
  const [
    potenzaFrigoriferaNominaleAssorbitaUpdate,
    setPotenzaFrigoriferaNominaleAssorbitaUpdate,
  ] = useState("");
  const [
    potenzaTermicaNominaleAssorbitaUpdate,
    setPotenzaTermicaNominaleAssorbitaUpdate,
  ] = useState("");

  // Caratteristiche
  const [climatizzazioneEstivaUpdate, setClimatizzazioneEstivaUpdate] =
    useState(false);

  const [climatizzazioneInvernaleUpdate, setClimatizzazioneInvernaleUpdate] =
    useState(false);
  const [produzioneACSUpdate, setProduzioneACSUpdate] = useState(false);

  // Note
  const [noteMacchinaUpdate, setNoteMacchinaUpdate] = useState("");

  // Lista delle Tipologie d'Intervento
  const [listaTipologieIntervento, setListaTipologieIntervento] = useState([]);

  // Per scegliere cliccando i bottoni le tipologie di intervento
  const [buttonTipologiaIntervento, setButtonTipologiaIntervento] = useState(
    {}
  );

  // Creazione dell'intervento per la Macchina
  const [interventoGaranzia, setInterventoGaranzia] = useState(false);
  const [ricambiOrdinare, setRicambiOrdinare] = useState(false);
  const [textRicambiOrdinare, setTextRicambiOrdinare] = useState("");
  const [interventoFinito, setInterventoFinito] = useState("terminato");
  const [noteInterventoMacchina, setNoteInterventoMacchina] = useState("");

  // Per prendere la lista degli interventi effettuati sulla Macchina selezionata
  const [listaInterventiMacchina, setListaInterventiMacchina] = useState([]);

  // Per prendere i dettagli del singolo intervento sulla Macchina selezionata
  const [interventoMacchinaDetail, setInterventoMacchinaDetail] = useState([0]);

  // Per la creazione di una nuova Lettura per la Macchina selezionata
  const [certificazioneLettura, setCertificazioneLettura] = useState(false);
  const [surriscaldamentoMacchina, setSurriscaldamentoMacchina] = useState("");
  const [sottoraffreddamentoMacchina, setSottoraffreddamentoMacchina] =
    useState("");
  const [tempCondensazioneMacchina, setTempCondensazioneMacchina] =
    useState("");
  const [tempEvaporazioneMacchina, setTempEvaporazioneMacchina] = useState("");
  const [tempIngressoLatoEsternoMacchina, setTempIngressoLatoEsternoMacchina] =
    useState("");
  const [tempUscitaLatoEsternoMacchina, setTempUscitaLatoEsternoMacchina] =
    useState("");
  const [tempIngressoLatoUtenzeMacchina, setTempIngressoLatoUtenzeMacchina] =
    useState("");
  const [tempUscitaLatoUtenzeMacchina, setTempUscitaLatoUtenzeMacchina] =
    useState("");
  const [potenzaAssorbitaMacchina, setPotenzaAssorbitaMacchina] = useState("");

  const [provaEseguitaInModalita, setProvaEseguitaInModalita] = useState("-");
  const [numeroCircuito, setNumeroCircuito] = useState("");

  // Lista delle Letture della Macchina
  const [listaLettureMacchina, setListaLettureMacchina] = useState([]);

  // Dettaglio della Lettura della Macchina
  const [dettagliLetturaMacchina, setDettagliLetturaMacchina] = useState({});

  // Unita
  // Form di Ricerca nella Lista delle Unita
  const [searchUnita, setSearchUnita] = useState("");

  // Prendere i dettagli della singola Unità
  const [dettaglioUnita, setDettaglioUnita] = useState({});

  // Lista Interventi delle Unità
  const [listaInterventiUnita, setListaInterventiUnita] = useState([]);

  // DettaglioInterventoUnita
  const [interventoUnitaDetail, setInterventoUnitaDetail] = useState([]);

  // <------------------ Cliente ------------------>
  const [searchDocumento, setSearchDocumento] = useState("");
  const [listaDocumenti, setListaDocumenti] = useState([]);
  const [dettaglioMacchinaCliente, setDettaglioMacchinaCliente] = useState([]);

  // Documenti Dettaglio Macchina
  const [listaDocumentiMacchina, setListaDocumentiMacchina] = useState([]);
  const [searchDocumentoMacchina, setSearchDocumentoMacchina] = useState("");

  // PROGETTO EVENT PLATFORM CRIMESTOP

  const loginUrl = new URL(window.location.href);
  const eventCode = loginUrl.searchParams.get("eventCode");
  const autologinCode = loginUrl.searchParams.get("autologinCode");
  const sessionId = loginUrl.searchParams.get("session");
  const codeReset = loginUrl.searchParams.get("codeReset");

  // Dettagli eventi cookies

  let dettagliEvento = "";
  console.log(Cookies.get("eventDetails"));
  if (
    Cookies.get("eventDetails") &&
    Cookies.get("eventDetails") !== "undefined"
  ) {
    dettagliEvento = JSON.parse(Cookies.get("eventDetails"));
  }

  const [isError, setIsError] = useState(false);

  const [eventDetails, setEventDetails] = useState([]);

  // Scarica la lista dei clienti
  const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
  const { toPDF, targetRef } = usePDF({
    filename: `lista-partecipanti-${timestamp}.pdf`,
  });

  return (
    <StateContext.Provider
      value={{
        toPDF,
        targetRef,
        // <------------------------- Progetto Event Platform ------------------------->
        isError,
        setIsError,
        pageTitle,
        setPageTitle,
        token,
        loading,
        setLoading,
        error,
        setError,

        AllUserData,
        idUtenteLoggato,
        ragioneSociale,
        userLastname,
        userEmail,
        userRoles,
        firstName,

        // --- Lista Uscite

        listaUscite,
        setListaUscite,

        // --- Dettagli Generici Uscita Cliente
        dettagliGenericiUscita,
        setDettagliGenericiUscita,
        telefonoClienteUscita,
        setTelefonoClienteUscita,
        cellulareClienteUscita,
        setCellulareClienteUscita,
        pIvaUscitaClienteUscita,
        setPIvaUscitaClienteUscita,
        cFiscaleClienteUscita,
        setCFiscaleClienteUscita,
        emailClienteUscita,
        setEmailClienteUscita,

        // --- Lista Macchine
        listaMacchine,
        setListaMacchine,

        // --- Dettaglio Macchina

        dettaglioMacchina,
        setDettaglioMacchina,

        // --- Lista Unità

        listaUnita,
        setListaUnita,

        // --- Campi delle Note del Tecnico per la chiusura dell'Uscita

        oraInizio,
        setOraInizio,
        metodoPagamento,
        setMetodoPagamento,
        costoIntervento,
        setCostoIntervento,
        cartaPreventivo,
        setCartaPreventivo,
        invioInformativa,
        setInvioInformativa,
        noteTecnico,
        setNoteTecnico,
        noteTecnicoUfficio,
        setNoteTecnicoUfficio,

        // --- Form di ricerca all'interno della Lista Macchine
        searchMacchina,
        setSearchMacchina,

        // --- Dati Macchina per creazione e modifica
        fabbricante,
        setFabbricante,
        modello,
        setModello,
        riferimentoImmobile,
        setRiferimentoImmobile,
        matricola,
        setMatricola,
        numeroCircuiti,
        setNumeroCircuiti,
        tipologia,
        setTipologia,
        numeroProgressivo,
        setNumeroProgressivo,

        // Lista tipologie Macchina
        listTipologieMacchina,
        setListTipologieMacchina,

        // Lista tipologie Gas Macchina
        listTipologieGas,
        setListTipologieGas,

        // Potenze
        potenzaTermicaNominaleTotaleMax,
        setPotenzaTermicaNominaleTotaleMax,
        potenzaFrigoriferaNominaleRaffrescamento,
        setPotenzaFrigoriferaNominaleRaffrescamento,
        potenzaFrigoriferaNominaleRiscaldamento,
        setPotenzaFrigoriferaNominaleRiscaldamento,
        potenzaFrigoriferaNominaleAssorbita,
        setPotenzaFrigoriferaNominaleAssorbita,
        potenzaTermicaNominaleAssorbita,
        setPotenzaTermicaNominaleAssorbita,

        // Gas
        gas,
        setGas,
        quantitativoGasDaTarga,
        setQuantitativoGasDaTarga,
        quantitativoGasAggiunto,
        setQuantitativoGasAggiunto,

        // Caratteristiche
        climatizzazioneEstiva,
        setClimatizzazioneEstiva,
        climatizzazioneInvernale,
        setClimatizzazioneInvernale,
        produzioneACS,
        setProduzioneACS,

        // Note
        noteMacchina,
        setNoteMacchina,

        // Update della Macchina

        // Dati Macchina
        fabbricanteUpdate,
        setFabbricanteUpdate,
        modelloUpdate,
        setModelloUpdate,
        riferimentoImmobileUpdate,
        setRiferimentoImmobileUpdate,
        matricolaUpdate,
        setMatricolaUpdate,
        numeroCircuitiUpdate,
        setNumeroCircuitiUpdate,
        tipologiaUpdate,
        setTipologiaUpdate,
        numeroProgressivoUpdate,
        setNumeroProgressivoUpdate,

        // Potenze
        potenzaTermicaNominaleTotaleMaxUpdate,
        setPotenzaTermicaNominaleTotaleMaxUpdate,
        potenzaFrigoriferaNominaleRaffrescamentoUpdate,
        setPotenzaFrigoriferaNominaleRaffrescamentoUpdate,
        potenzaFrigoriferaNominaleRiscaldamentoUpdate,
        setPotenzaFrigoriferaNominaleRiscaldamentoUpdate,
        potenzaFrigoriferaNominaleAssorbitaUpdate,
        setPotenzaFrigoriferaNominaleAssorbitaUpdate,
        potenzaTermicaNominaleAssorbitaUpdate,
        setPotenzaTermicaNominaleAssorbitaUpdate,

        // Gas
        gasUpdate,
        setGasUpdate,
        quantitativoGasDaTargaUpdate,
        setQuantitativoGasDaTargaUpdate,
        quantitativoGasAggiuntoUpdate,
        setQuantitativoGasAggiuntoUpdate,

        // Caratteristiche
        climatizzazioneEstivaUpdate,
        setClimatizzazioneEstivaUpdate,
        climatizzazioneInvernaleUpdate,
        setClimatizzazioneInvernaleUpdate,
        produzioneACSUpdate,
        setProduzioneACSUpdate,

        // Note
        noteMacchinaUpdate,
        setNoteMacchinaUpdate,

        // Lista Tipologie Intervento
        listaTipologieIntervento,
        setListaTipologieIntervento,

        // Per Scegliere le tipologie di intervento
        buttonTipologiaIntervento,
        setButtonTipologiaIntervento,

        // Creazione Intervento per la Macchina
        interventoGaranzia,
        setInterventoGaranzia,
        ricambiOrdinare,
        setRicambiOrdinare,
        textRicambiOrdinare,
        setTextRicambiOrdinare,
        interventoFinito,
        setInterventoFinito,
        noteInterventoMacchina,
        setNoteInterventoMacchina,

        // Per prendere la lista degli interventi effettuati su una Macchina
        listaInterventiMacchina,
        setListaInterventiMacchina,

        // I dettagli dell'intervento della Macchina
        interventoMacchinaDetail,
        setInterventoMacchinaDetail,

        // Per la creazione di una nuova Lettura per la Macchina selezionata
        certificazioneLettura,
        setCertificazioneLettura,
        surriscaldamentoMacchina,
        setSurriscaldamentoMacchina,
        sottoraffreddamentoMacchina,
        setSottoraffreddamentoMacchina,
        tempCondensazioneMacchina,
        setTempCondensazioneMacchina,
        tempEvaporazioneMacchina,
        setTempEvaporazioneMacchina,
        tempIngressoLatoEsternoMacchina,
        setTempIngressoLatoEsternoMacchina,
        tempUscitaLatoEsternoMacchina,
        setTempUscitaLatoEsternoMacchina,
        tempIngressoLatoUtenzeMacchina,
        setTempIngressoLatoUtenzeMacchina,
        tempUscitaLatoUtenzeMacchina,
        setTempUscitaLatoUtenzeMacchina,
        potenzaAssorbitaMacchina,
        setPotenzaAssorbitaMacchina,
        provaEseguitaInModalita,
        setProvaEseguitaInModalita,
        numeroCircuito,
        setNumeroCircuito,

        // Recuperare la lista delle letture della Macchina
        listaLettureMacchina,
        setListaLettureMacchina,

        // Dettagli della Lettura della Macchina
        dettagliLetturaMacchina,
        setDettagliLetturaMacchina,

        // Indicatore Numerazione Macchina
        indicatoreNumerazioneMacchina,
        setIndicatoreNumerazioneMacchina,

        // <------------------- Unità ------------------------>
        searchUnita,
        setSearchUnita,

        // Dettagli della singola Unità
        dettaglioUnita,
        setDettaglioUnita,

        // Lista interventi delle Macchina
        listaInterventiUnita,
        setListaInterventiUnita,

        // Dettaglio Intervento Macchina
        interventoUnitaDetail,
        setInterventoUnitaDetail,

        // ----------------------------------------------------------------------
        // < ----------------------------- CLIENTE ----------------------------->
        // ----------------------------------------------------------------------

        // Lista Documenti
        listaDocumenti,
        setListaDocumenti,

        // Cerca Documento
        searchDocumento,
        setSearchDocumento,

        // Dettagli Macchina Cliente
        dettaglioMacchinaCliente,
        setDettaglioMacchinaCliente,

        // Lista Documenti Macchina
        listaDocumentiMacchina,
        setListaDocumentiMacchina,

        // Cerca Documento Macchina
        searchDocumentoMacchina,
        setSearchDocumentoMacchina,

        // Numero progressivo macchina corrente per cliente
        numeroProgressivoMacchinaCliente,

        // Numero progressivo macchina corrente
        numeroProgressivoCurrentMacchina,

        // Numero progressivo unità corrente
        numeroProgressivoCurrentUnit,

        // <---------------------------------- PROGETTO EVENT PLATFORM CRIMESTOP ---------------------------------->

        dettagliEvento,

        eventDetails,
        setEventDetails,

        codeReset,
        eventCode,
        autologinCode,
        sessionId,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export default StateContainer;
