import React from 'react'

const Loading = () => {
  return (
    <div className="spinner-border text-white" role="status" style={{width: "100px", height: "100px"}}>
        <span className="visually-hidden">Loading...</span>
    </div>
  )
}

export default Loading