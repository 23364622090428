import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import { StateContext } from "../components/StateContainer";
import { Link, useParams } from "react-router-dom";
import Logout from "./components/logout/Logout";

function NoteGenerali() {
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    eventCode,
    autologinCode,
    token,
    dettagliEvento,
  } = useContext(StateContext);
  const { idSessione } = useParams();

  const [dettagliNoteGenerali, setDettagliNoteGenerali] = useState([]);

  useEffect(() => {
    setPageTitle("Note generali");
  }, []);

  // Gestore di eventi per l'aggiornamento dei valori degli input
  const handleChange = (key, value) => {
    // Aggiorna lo stato con il nuovo valore per la chiave specificata
    console.log(value);
    setDettagliNoteGenerali((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        setDettagliNoteGenerali(response.data.response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const AggiornaDatiNoteGenerali = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/update`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
        sessioneId: idSessione,
        datiPartecipazione: dettagliNoteGenerali,
      },
    })
      .then((response) => {
        window.location.replace(
          `/ringraziamento?eventCode=${eventCode}&autologinCode=${autologinCode}`
        );
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };
  return (
    <div
      className="account-pages bg-grey-stellantis"
      style={{
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <div className="container">
        <div
          className="row"
          style={{
            // minHeight: "100vh",
            height: "auto",
          }}
        >
          <div
            className="col-12 p-0"
            style={{ height: "100px", marginBottom: "60px" }}
          >
            <div className="box-settings-sessioni position-relative">
              <Logout />
              <img src={`${dettagliEvento.logoEvento}`} alt="" />
              <div className="container-nome-evento-sessioni">
                <div className="box-nome-evento-sessioni">
                  {dettagliEvento.nomeEvento}
                </div>
                <div className="box-caricamento p-0 bg-white">
                  <div
                    className="progress-bar progress-bar-selezione progress-bar-note"
                    role="progressbar"
                    style={{
                      width: "100%",
                    }}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" style={{ marginBottom: "30px" }}>
            <h5
              className="text-center"
              style={{ fontSize: "25px", fontWeight: "bold" }}
            >
              Note
            </h5>
            <p className="text-muted text-center">
              Inserire eventuali note particolari
              <br />o specifiche richieste da segnalare.
            </p>
          </div>

          {/* Inserire eventuali note  */}
          <div className="col-12">
            <div class="form-floating my-4">
              <textarea
                className="form-control border-0"
                placeholder="Inserire eventuali note"
                id="floatingTextarea2"
                style={{ minHeight: "400px", borderRadius: "5%" }}
                value={dettagliNoteGenerali.noteGenerali}
                onChange={(e) => handleChange("noteGenerali", e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className="col-12">
            <div className="my-3 text-end d-flex align-items-center justify-content-between">
              <Link
                to={
                  dettagliNoteGenerali.abilitaAccomodation
                    ? `/sessioni/${idSessione}/accomodation?eventCode=${eventCode}&autologinCode=${autologinCode}`
                    : !dettagliNoteGenerali.abilitaAccomodation &
                      dettagliNoteGenerali.abilitaTrasporti
                    ? `/sessioni/${idSessione}/mezzi-trasporto?eventCode=${eventCode}&autologinCode=${autologinCode}`
                    : `/sessioni/${idSessione}/caricamento-documenti?eventCode=${eventCode}&autologinCode=${autologinCode}`
                }
                className="btn bg-blue-stellantis w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                type="submit"
              >
                Indietro
              </Link>
              <button
                onClick={AggiornaDatiNoteGenerali}
                className="btn bg-green-stellantis w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                type="submit"
              >
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoteGenerali;
