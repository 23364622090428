import React, { useContext, useEffect, useState } from "react";
import "../../../App.css";

import { StateContext } from "../../../components/StateContainer";

const DocumentoSessioni = () => {
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    eventCode,
    autologinCode,
    AllUserData,
    token,
    sessionId,
    dettagliEvento,
    toPDF,
    targetRef,
  } = useContext(StateContext);

  return <div ref={targetRef}>DocumentoSessioni</div>;
};

export default DocumentoSessioni;
