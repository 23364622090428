import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import { StateContext } from "../components/StateContainer";
function PartecipoPage() {
  const {
    loading,
    setLoading,
    setError,
    error,
    setPageTitle,
    eventCode,
    autologinCode,
    dettagliEvento,
    token,
  } = useContext(StateContext);

  const [dettagliPartecipazione, setDettagliPartecipazione] = useState({});
  const [acconsentoTrattamentoDati, setAcconsentoTrattamentoDati] =
    useState(false);
  const [mantieniDati, setMantieniDati] = useState(false);

  useEffect(() => {
    setPageTitle("Partecipo");
  }, []);

  const ConfermaDatiPartecipazione = () => {
    setError("");
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/conferma-dati`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
        acconsentoTrattamentoDati: acconsentoTrattamentoDati,
        mantieniDati: mantieniDati,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data && response.data.error) {
          setError(response.data.error);
        } else {
          const sessionId = response.data.response.sessioneId;
          const abilitaTrasporti = response.data.response.abilitaTrasporti;
          const abilitaAccomodation =
            response.data.response.abilitaAccomodation;
          if (sessionId == null) {
            window.location.replace(
              `/sessioni?eventCode=${eventCode}&autologinCode=${autologinCode}`
            );
          } else {
            window.location.replace(
              `/sessioni/${sessionId}/anagrafica-cliente?eventCode=${eventCode}&autologinCode=${autologinCode}`
            );
          }
        }
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };

  useEffect(() => {
    setError("");
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data && response.data.error) {
          setError(response.data.error);
        } else {
          console.log(response);

          setDettagliPartecipazione(response.data.response);
        }
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  }, []);

  return (
    <div
      className="account-pages bg-blue-stellantis"
      style={{
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <div
        className="container-fluid"
        style={{
          background: `url(${dettagliEvento.coverEvento}) center center / cover no-repeat`,
          height: "auto",
          minHeight: "15vh",
        }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <img
              src={`${dettagliEvento.logoEvento}`}
              alt=""
              className="mt-2 logo-settings img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="row align-items-center justify-content-center d-flex flex-column"
          style={{ display: "flex", flexDirection: "column", height: "auto" }}
        >
          <div
            className="col-12 position-relative align-items-end mt-4"
            style={{ flexGrow: "1" }}
          >
            <div className="box-nome-evento">{dettagliEvento.nomeEvento}</div>
            <h1
              className="text-center text-white mt-5 mb-5"
              style={{ fontWeight: "lighter", fontSize: "25pt" }}
            >
              PARTECIPO
            </h1>
            <div style={{ marginTop: "50px" }}>
              <p className="text-white" style={{ fontSize: "16px" }}>
                Prima di procedere, desideriamo confermare il consenso al
                trattamento dei suoi dati personali per partecipare all’evento.
                <br></br>
                <br></br>
                Se d’accordo, la preghiamo di spuntare la casella di conferma
                qui sotto.
              </p>
            </div>
            {error && (
              <div
                class="alert alert-danger text-black mt-5 text-center"
                role="alert"
              >
                {error}
              </div>
            )}
            <div className="bg-white p-4 mt-5">
              <div className="form-check d-flex align-items-center gap-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  value={acconsentoTrattamentoDati}
                  onChange={() =>
                    setAcconsentoTrattamentoDati(!acconsentoTrattamentoDati)
                  }
                />
                <label className="form-check-label" for="flexCheckDefault">
                  Acconsento al trattamento<br></br>dei dati personali
                </label>
              </div>
            </div>

            <div className="bg-white p-4 mt-4">
              <div className="form-check d-flex align-items-center gap-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="nonMantenereDati"
                  value={mantieniDati}
                  onChange={() => setMantieniDati(!mantieniDati)}
                />
                <label className="form-check-label" for="nonMantenereDati">
                  Non mantenere i miei dati in seguito<br></br>alla chiusura
                  dell'evento
                </label>
              </div>
            </div>

            <div className="mt-5 text-end d-flex align-items-center justify-content-between mb-4">
              <button
                className="btn bg-white w-sm waves-effect waves-light w-100 text-blue-stellantis py-3"
                type="submit"
                onClick={ConfermaDatiPartecipazione}
              >
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartecipoPage;
