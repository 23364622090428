import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import useAuth from "../UseAuth";
import { StateContext } from "../components/StateContainer";
import { Link, redirect } from "react-router-dom";
import Cookies from "js-cookie";
function LoginHostess() {
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    eventDetails,
    isError,
    setIsError,
    setEventDetails,
  } = useContext(StateContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  useEffect(() => {
    setPageTitle("Login");
  }, []);

  const loginUrl = new URL(window.location.href);
  const eventCode = loginUrl.searchParams.get("eventCode");
  const autologinCode = loginUrl.searchParams.get("autologinCode");

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_URL}/evento/hostess/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        eventCode: eventCode,
      },
    })
      .then((response) => {
        const eventDetails = response.data.response;
        Cookies.set("eventDetails", JSON.stringify(eventDetails), {
          expires: 0.5,
        });
        setEventDetails(eventDetails);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSubmit = (event) => {
    setIsError("");
    event.preventDefault();
    setLoading(true);
    const loginPayload = {
      email: email,
      password: password,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/login_check`, loginPayload)
      .then(
        (response) => {
          setLoading(false);
          setIsError("");
          const token = response.data.token;
          login(token);
        },
        (reason) => {
          setLoading(false);
          setIsError(true);
          console.error(reason);
          if (reason.response.data.code === 401) {
            setIsError("Email o password errati");
          } else {
            setIsError("C'è stato un errore nel server. Riprovare più tardi.");
          }
        }
      );
  };

  return (
    <div
      className="account-pages bg-blue-stellantis"
      style={{
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <div
        className="container-fluid"
        style={{
          background: `url(${
            (eventDetails && eventDetails.coverEvento) || ""
          }) center center / cover no-repeat`,
          height: "auto",
          minHeight: "50vh",
        }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <img
              src={`${(eventDetails && eventDetails.logoEvento) || ""}`}
              alt=""
              className="mt-2 logo-settings img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="row align-items-center justify-content-center d-flex flex-column"
          style={{ display: "flex", flexDirection: "column", height: "auto" }}
        >
          <div
            className="col-12 position-relative align-items-end mt-4"
            style={{ flexGrow: "1" }}
          >
            <div className="box-nome-evento">
              {(eventDetails && eventDetails.nomeEvento) || "-"}
            </div>
            <div className="bg-blue-stellantis mt-5">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label text-white" htmlFor="username">
                    Nome utente
                  </label>
                  <input
                    type="text"
                    className="form-control  bg-transparent input-login-stellantis"
                    id="username"
                    placeholder="Inserisci il nome utente"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label
                    className="form-label text-white mt-2"
                    htmlFor="userpassword"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control bg-transparent input-login-stellantis mb-3"
                    id="userpassword"
                    placeholder="Inserisci la password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="mt-5 text-end d-flex align-items-center justify-content-between">
                  <button
                    className="btn bg-white w-sm waves-effect waves-light w-100 text-blue-stellantis me-2 py-3"
                    type="submit"
                  >
                    Accedi
                  </button>
                </div>
                {isError && (
                  <div
                    class="alert alert-danger text-black mt-3 text-center"
                    role="alert"
                  >
                    {isError}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginHostess;
