import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import useAuth from "../UseAuth";
import { StateContext } from "../components/StateContainer";
import { Link, redirect } from "react-router-dom";
import Cookies from "js-cookie";

const CreaNuovaPassword = () => {
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    eventDetails,
    setEventDetails,
  } = useContext(StateContext);

  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { login } = useAuth();
  const [modificaPassword, setModificaPassword] = useState(false);

  useEffect(() => {
    setPageTitle("Login");
  }, []);

  const loginUrl = new URL(window.location.href);
  const eventCode = loginUrl.searchParams.get("eventCode");
  const autologinCode = loginUrl.searchParams.get("autologinCode");
  const codeReset = loginUrl.searchParams.get("codeReset");

  useEffect(() => {
    console.log(eventCode);
    console.log(autologinCode);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_URL}/evento/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        const eventDetails = response.data.response;
        Cookies.set("eventDetails", JSON.stringify(eventDetails), {
          expires: 0.5,
        });
        setEventDetails(eventDetails);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSubmit = (event) => {
    setIsError("");
    setModificaPassword(false);
    event.preventDefault();
    setLoading(true);

    if (confirmPassword !== password) {
      setIsError("La nuova password e la sua conferma non corrispondono.");
    } else {
      const loginPayload = {
        codeReset: codeReset,
        password: confirmPassword,
      };
      axios
        .post(
          `${process.env.REACT_APP_PUBLIC_URL}/create-new-password`,
          loginPayload
        )
        .then(
          (response) => {
            if (response.data.response.modifica_password) {
              setLoading(false);
              setIsError("");
              setModificaPassword(true);
            } else {
              setIsError("Non è stato possibile modificare la password");
              setModificaPassword(false);
            }
          },
          (reason) => {
            setLoading(false);
            setIsError(true);
            console.error(reason);
            if (reason.response.data.code === 401) {
              setIsError("Non è stato possibile modificare la password");
            } else {
              setIsError(
                "C'è stato un errore nel server. Riprovare più tardi."
              );
            }
          }
        );
    }
  };

  return (
    <div
      className="account-pages bg-blue-stellantis"
      style={{
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <div
        className="container-fluid"
        style={{
          background: `url(${
            (eventDetails && eventDetails.coverEvento) || ""
          }) center center / cover no-repeat`,
          height: "auto",
          minHeight: "50vh",
        }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <img
              src={`${(eventDetails && eventDetails.logoEvento) || ""}`}
              alt=""
              className="mt-2 logo-settings img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="row align-items-center justify-content-center d-flex flex-column"
          style={{ display: "flex", flexDirection: "column", height: "auto" }}
        >
          <div
            className="col-12 position-relative align-items-end mt-4"
            style={{ flexGrow: "1" }}
          >
            <div className="box-nome-evento">
              {(eventDetails && eventDetails.nomeEvento) || "-"}
            </div>

            {modificaPassword ? (
              <div className="bg-blue-stellantis my-5">
                <h5 className="fw-bold text-white">
                  Password modificata correttamente
                </h5>
                <p className="text-white">
                  La password è stata modificata correttamente, per effettuare
                  l'accesso tornare alla login
                </p>
                <Link
                  className="btn bg-white w-sm waves-effect waves-light w-100 text-blue-stellantis py-3 mt-4"
                  to={`/login?eventCode=${eventCode}&autologinCode=${autologinCode}`}
                >
                  Torna alla login
                </Link>
              </div>
            ) : (
              <div className="bg-blue-stellantis mt-5">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label
                      className="form-label text-white mt-2"
                      htmlFor="userpassword"
                    >
                      Inserisci nuova password
                    </label>
                    <input
                      type="password"
                      className="form-control bg-transparent input-login-stellantis mb-3"
                      id="userpassword"
                      placeholder="Inserisci la password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      className="form-label text-white mt-2"
                      htmlFor="userpassword"
                    >
                      Conferma nuova password
                    </label>
                    <input
                      type="password"
                      className="form-control bg-transparent input-login-stellantis mb-3"
                      id="userpassword"
                      placeholder="Conferma la password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>

                  <div className="mt-5 text-end d-flex align-items-center justify-content-between">
                    <button
                      className={`btn bg-white w-sm waves-effect waves-light w-100 text-blue-stellantis me-2 py-3 ${
                        password === "" || confirmPassword === ""
                          ? "disabled"
                          : ""
                      }`}
                      type="submit"
                    >
                      Modifica password
                    </button>
                  </div>
                  {isError && (
                    <div
                      class="alert alert-danger text-black mt-3 text-center"
                      role="alert"
                    >
                      {isError}
                    </div>
                  )}
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreaNuovaPassword;
