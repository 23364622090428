import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import "../../../App.css";
import { StateContext } from "../../../components/StateContainer";
import { Link, useParams } from "react-router-dom";
import Logout from "../logout/Logout";
import Webcam from "react-webcam";
import axios from "axios";
import Tesseract from "tesseract.js";
import SignatureCanvas from "react-signature-canvas";
import { FaCamera as Fotocamera } from "react-icons/fa";
import { GrClose as Close } from "react-icons/gr";
import { FaRotate as Rotate } from "react-icons/fa6";

function ScannerQrCodePartecipante({
  handleChange,
  accendiFotocamera,
  setAccendiFotocamera,
  codiceValido,
  setCodiceValido,
  firmaInserita,
  setFirmaInserita,
}) {
  const sigCanvasRef = useRef({});

  //   const [accendiFotocamera, setAccendiFotocamera] = useState(false);
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [ocrText, setOcrText] = useState("");
  const [loading, setLoading] = useState(false);
  const [changeCameraAngle, setChangeCameraAngle] = useState(false);
  const { idSessione } = useParams();
  const [dataScadenza, setDataScadenza] = useState("");
  const [temporaryDate, setTemporaryDate] = useState("");
  const { setPageTitle, eventCode, autologinCode, token, dettagliEvento } =
    useContext(StateContext);

  useEffect(() => {
    setPageTitle("Caricamento Documenti");
  }, []);

  const GetCodeQrCode = (result) => {
    setLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/check-qrcode`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        codeQRCode: result[0].rawValue,
      },
    })
      .then((response) => {
        setLoading(false);
        setCodiceValido(response.data.response.codiceValido);
        // window.location.replace(
        //   `/sessioni/${idSessione}/accomodation?eventCode=${eventCode}&autologinCode=${autologinCode}`
        // );
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };

  const AnnullaProcesso = () => {
    setImageSrc("");
    setOcrText("");
    setDataScadenza("");
    setAccendiFotocamera(false);
    setCodiceValido("");
    setTemporaryDate("");
  };

  const [contentData, setContentData] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [currentRequestId, setCurrentRequestId] = useState(null);

  // const uploadFirmaCliente = () => {
  //   console.log(sigCanvasRef);
  //   const dataUrl = sigCanvasRef.current.toDataURL("image/png");

  //   const imageName = "signature.png"; // Nome dell'immagine

  //   console.log(dataUrl);
  //   console.log(sigCanvasRef);
  //   const cancelFile = axios.CancelToken.source();
  //   setUploadProgress((prevProgress) => [
  //     ...prevProgress,
  //     {
  //       file: dataUrl,
  //       progress: 0,
  //       status: "uploading",
  //       cancelToken: cancelFile,
  //     },
  //   ]);
  //   setContentData((currentScene) => [...currentScene, dataUrl]);

  //   const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
  //   const requestId = `upload_${timestamp}_${imageName}`;
  //   setCurrentRequestId(requestId);
  //   const config = {
  //     method: "POST",
  //     url: `${process.env.REACT_APP_BASE_URL}/upload-firma-cliente`,
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     },
  //     data: {
  //       keyName: requestId,
  //       signature: dataUrl,
  //       codePartecipazione: codiceValido,
  //     },
  //   };

  //   axios(config).then((res) => {
  //     console.log(res);
  //   });
  // };

  const uploadFirmaCliente = () => {
    const dataUrl = sigCanvasRef.current.toDataURL("image/png");

    if (dataUrl) {
      const targetWidth = 800;
      const targetHeight = 500;

      // Creare un elemento immagine per caricare il file dal dataUrl
      const img = new Image();
      img.src = dataUrl;

      img.onload = function () {
        const width = img.width;
        const height = img.height;

        // Crea un canvas con le dimensioni target
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = targetWidth;
        canvas.height = targetHeight;

        // Calcola il rapporto di ridimensionamento
        const scaleX = targetWidth / width;
        const scaleY = targetHeight / height;

        // Disegna l'immagine ridimensionata sul canvas con le dimensioni target
        ctx.drawImage(
          img,
          0,
          0,
          width,
          height,
          0,
          0,
          targetWidth,
          targetHeight
        );

        // Converti il canvas in un Blob
        canvas.toBlob((blob) => {
          if (blob) {
            // Il file è stato ridotto con successo
            const compressedFile = new File([blob], "firma_compressa.png", {
              type: "image/png",
            });
            startUpload([compressedFile]);
          }
        }, "image/png");
      };
    } else {
      return;
    }
  };

  const startUpload = (dataUrl) => {
    const imageName = "signature"; // Nome dell'immagine

    const cancelFile = axios.CancelToken.source();
    setUploadProgress((prevProgress) => [
      ...prevProgress,
      {
        file: dataUrl[0],
        progress: 0,
        status: "uploading",
        cancelToken: cancelFile,
      },
    ]);
    setContentData((currentScene) => [...currentScene, dataUrl[0]]);

    const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
    const requestId = `upload_${imageName}_${timestamp}.png`;
    setCurrentRequestId(requestId);

    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/attachment-presigned-url`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        keyName: requestId,
      },
    };

    axios(config)
      .then((res) => {
        delete axios.defaults.headers.common["Authorization"];
        const configSecondPart = {
          method: "put",
          url: res.data.response.presignedUrl,
          headers: {
            Accept: `image/png`,
            "Content-Type": `image/png`,
          },
          data: dataUrl[0],
          cancelToken: cancelFile.token,
          onUploadProgress: (progressEvent) => {
            let percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        };
        axios(configSecondPart)
          .then((res) => {
            delete axios.defaults.headers.common["Authorization"];
            const configThirdPart = {
              method: "POST",
              // url: `${process.env.REACT_APP_BASE_URL}/upload-chat-icon`,
              url: `${process.env.REACT_APP_BASE_URL}/upload-firma-cliente`,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
              data: {
                keyName: requestId,
                codePartecipazione: codiceValido,
              },
            };

            axios(configThirdPart)
              .then((res) => {
                setImageSrc("");
                setOcrText("");
                setDataScadenza("");
                setAccendiFotocamera(false);
                setCodiceValido("");
                setTemporaryDate("");
                setFirmaInserita(true);
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
            } else {
              console.log("AXIOS ERROR 2: ", err);
            }
          });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log(`Request ${requestId} canceled`);
        } else {
          console.log("AXIOS ERROR 1: ", err);
        }
      });
  };

  // const j = (file) => {
  //   const cancelFile = axios.CancelToken.source();
  //   setUploadProgress((prevProgress) => [
  //     ...prevProgress,
  //     {
  //       file: file[0],
  //       progress: 0,
  //       status: "uploading",
  //       cancelToken: cancelFile,
  //     },
  //   ]);
  //   setContentData((currentScene) => [...currentScene, file[0]]);

  //   const requestId = `upload_${file[0].name}`;
  //   setCurrentRequestId(requestId);

  //   const config = {
  //     method: "POST",
  //     url: `${process.env.REACT_APP_BASE_URL}/attachment-presigned-url`,
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     },
  //     data: {
  //       keyName: `${file[0].name}`,
  //     },
  //   };

  //   axios(config)
  //     .then((res) => {
  //       delete axios.defaults.headers.common["Authorization"];
  //       const configSecondPart = {
  //         method: "put",
  //         url: res.data.response.presignedUrl,
  //         headers: {
  //           Accept: `${file[0].type}`,
  //           "Content-Type": `${file[0].type}`,
  //         },
  //         data: file[0],
  //         cancelToken: cancelFile.token,
  //         onUploadProgress: (progressEvent) => {
  //           let percentage = Math.round(
  //             (progressEvent.loaded * 100) / progressEvent.total
  //           );
  //         },
  //       };
  //       axios(configSecondPart)
  //         .then((res) => {
  //           delete axios.defaults.headers.common["Authorization"];
  //           const configThirdPart = {
  //             method: "POST",
  //             url: `${process.env.REACT_APP_BASE_URL}/upload-chat-icon`,
  //             headers: {
  //               Accept: "application/json",
  //               "Content-Type": "application/json",
  //               Authorization: "Bearer " + token,
  //             },
  //             data: {
  //               keyName: file[0].name,
  //               projectCode: id,
  //             },
  //           };

  //           axios(configThirdPart)
  //             .then((res) => {})
  //             .catch((e) => {
  //               console.log(e);
  //             });
  //         })
  //         .catch((err) => {
  //           if (axios.isCancel(err)) {
  //           } else {
  //             console.log("AXIOS ERROR 2: ", err);
  //           }
  //         });
  //     })
  //     .catch((err) => {
  //       if (axios.isCancel(err)) {
  //         console.log(`Request ${requestId} canceled`);
  //       } else {
  //         console.log("AXIOS ERROR 1: ", err);
  //       }
  //     });
  // };

  const previewStyle = {
    height: 240,
    width: 320,
    maxHeight: 320,
  };

  return (
    <div
      className="offcanvas offcanvas-start w-100"
      tabIndex="-1"
      id="scannerQrCodePartecipante"
      aria-labelledby="scannerQrCodePartecipante"
      style={{ overflowY: "scroll" }}
    >
      <div
        className="account-pages bg-grey-stellantis"
        style={{
          height: "100%",
        }}
      >
        <div className="container">
          <div
            className="row"
            style={{
              // minHeight: "100vh",
              height: "auto",
            }}
          >
            <div
              className="col-12 p-0"
              style={{ height: "100px", marginBottom: "60px" }}
            >
              <div className="box-settings-sessioni position-relative">
                <Logout />
                <img src={`${dettagliEvento.logoEvento}`} alt="" />
                <div className="container-nome-evento-sessioni">
                  <div className="box-nome-evento-sessioni">
                    {dettagliEvento.nomeEvento}
                  </div>
                  <div className="box-caricamento p-0 bg-white">
                    <div
                      className="progress-bar progress-bar-selezione progress-bar-documenti"
                      role="progressbar"
                      style={{
                        width: "50.1%",
                      }}
                      aria-valuenow="50.1"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12" style={{ marginBottom: "30px" }}>
              {codiceValido === "" ? (
                <>
                  <h5
                    className="text-center"
                    style={{ fontSize: "25px", fontWeight: "bold" }}
                  >
                    Scansione QR CODE
                  </h5>
                  <p className="text-muted text-center">
                    Posizionare il QR Code all'interno del riquadro segnato
                  </p>
                </>
              ) : (
                <>
                  <h5
                    className="text-center"
                    style={{ fontSize: "25px", fontWeight: "bold" }}
                  >
                    Inserire una firma
                  </h5>
                  <p className="text-muted text-center">
                    Tracciare una firma nello spazio sottostante per confermare
                    la partecipazione all'evento
                  </p>
                </>
              )}
            </div>

            <div className="col-12">
              <div>
                {accendiFotocamera ? (
                  codiceValido === "" ? (
                    <div className={"scanner mx-auto"}>
                      <Scanner
                        onScan={(result) => GetCodeQrCode(result)}
                        tracker={false}
                        // style={previewStyle}
                      />
                    </div>
                  ) : (
                    <SignatureCanvas
                      ref={sigCanvasRef}
                      penColor="black"
                      canvasProps={{
                        height: "500px",
                        className: "sigCanvas",
                      }}
                    />
                  )
                ) : (
                  <div
                    className="bg-dark"
                    style={{
                      height: "500px",
                      width: "100%",
                      maxHeight: "500px",
                    }}
                  ></div>
                )}
              </div>
            </div>
            <div className="col-12 mt-5">
              <div className="my-3 text-end d-flex align-items-center justify-content-between">
                <button
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  className="btn bg-blue-stellantis w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                  type="submit"
                  onClick={AnnullaProcesso}
                >
                  Annulla
                </button>
                {accendiFotocamera && codiceValido ? (
                  <button
                    // to={"/mezzi-trasporto"}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    className="btn bg-blue-stellantis w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                    type="submit"
                    // disabled={ocrText === "" ? true : false}
                    // onClick={AggiornaDatiDocumenti}
                    onClick={uploadFirmaCliente}
                  >
                    Conferma
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScannerQrCodePartecipante;
