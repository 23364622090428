import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "./components/loading/Loading";
import QRCode from "react-qr-code";
import { StateContext } from "../components/StateContainer";

function QrCodePartecipante() {
  const {
    error,
    setError,
    setPageTitle,
    dettagliEvento,
    eventCode,
    autologinCode,
    token,
  } = useContext(StateContext);

  useEffect(() => {
    setPageTitle("QrCodePartecipante");
  }, []);

  const [dettagliPartecipazione, setDettagliPartecipazione] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    console.log(eventCode);
    console.log(autologinCode);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        setLoading(false);
        console.log(response.data.response);
        setDettagliPartecipazione(response.data.response);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
        console.error("sono ", error);
      });
  }, []);

  //   console.log(dettagliPartecipazione.codiceQRCodePartecipazione);

  return (
    <div
      className="account-pages bg-blue-stellantis"
      style={{
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <div className="container">
        <div
          className="row d-block"
          style={{
            minHeight: "100vh",
            height: "auto",
          }}
        >
          <div className="col-12 p-0 m-0">
            <div className="box-settings p-0">
              <img src={`${dettagliEvento.logoEvento}`} alt="" />
            </div>
          </div>
          <div className="col-12 p-0 text-center mb-5">
            <h1
              className="text-center text-white mt-5 mb-5"
              style={{ fontWeight: "lighter", fontSize: "25pt" }}
            >
              {dettagliEvento.nomeEvento}
            </h1>
          </div>
          {loading &&
          dettagliPartecipazione.codiceQRCodePartecipazione === undefined ? (
            <div className="col-12 text-center">
              <Loading />
            </div>
          ) : (
            <div
              className="col-10 offset-1 bg-white p-2 d-flex align-items-center justify-content-center"
              style={{ height: "400px", borderRadius: "30px" }}
            >
              <QRCode
                size={256}
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  width: "240px",
                  height: "240px",
                }}
                value={
                  dettagliPartecipazione.codiceQRCodePartecipazione
                    ? dettagliPartecipazione.codiceQRCodePartecipazione
                    : ""
                }
                viewBox={`0 0 256 256`}
              />
            </div>
          )}
          <div
            className="col-12 text-white text-center mt-3"
            style={{ fontSize: "20px" }}
          >
            <p>Esibire il QR code in fase di check in.</p>
          </div>
          <div
            className="col-10 offset-1 text-white text-center mt-5"
            style={{ fontSize: "20px" }}
          >
            <Link
              to={`/anagrafica-completa-iscrizione?eventCode=${eventCode}&autologinCode=${autologinCode}`}
              className="btn bg-white text-blue-stellantis w-100 py-3"
            >
              Visualizza dati iscrizione
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrCodePartecipante;
