import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import useAuth from "../UseAuth";
import { StateContext } from "../components/StateContainer";
import { Link, useParams } from "react-router-dom";
import Logout from "./components/logout/Logout";
import { MdOutlineAirplanemodeActive as Plane } from "react-icons/md";
import { MdTrain as Train } from "react-icons/md";
import { MdDirectionsCarFilled as Car } from "react-icons/md";

function MezziTrasporto() {
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    eventCode,
    autologinCode,
    token,
    dettagliEvento,
  } = useContext(StateContext);

  const { idSessione } = useParams();

  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  const [mezzoScelto, setMezzoScelto] = useState("");
  const [dettagliMezziTrasporto, setDettagliMezziTrasporto] = useState([]);

  useEffect(() => {
    setPageTitle("Mezzi trasporto");
  }, []);

  // Gestore di eventi per l'aggiornamento dei valori degli input
  const handleChange = (key, value) => {
    // Aggiorna lo stato con il nuovo valore per la chiave specificata
    console.log(value);
    setDettagliMezziTrasporto((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  useEffect(() => {
    console.log(eventCode);
    console.log(autologinCode);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        console.log(response.data.response);
        setDettagliMezziTrasporto(response.data.response);
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  }, []);

  const AggiornaDatiMezziTrasporto = () => {
    console.log(eventCode);
    console.log(autologinCode);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/update`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
        datiPartecipazione: dettagliMezziTrasporto,
      },
    })
      .then((response) => {
        console.log(response.data.response);
        const abilitaAccomodation = response.data.response.abilitaAccomodation;
        if (!abilitaAccomodation) {
          window.location.replace(
            `/sessioni/${idSessione}/note-generali?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        } else {
          window.location.replace(
            `/sessioni/${idSessione}/accomodation?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };

  const inputInfoAereo = [
    {
      id: 1,
      label:
        dettagliMezziTrasporto.mezzoTrasporto === "aereo"
          ? "N° Volo di andata"
          : "N° Treno di andata",
      input: "voloAndataNumero",
      placeholder: "Inserisci numero volo andata",
      type: "text",
      value: dettagliMezziTrasporto.voloAndataNumero,
      displayWithAuto: false,
    },

    {
      id: 3,
      label:
        dettagliMezziTrasporto.mezzoTrasporto === "aereo"
          ? "N° Volo di ritorno"
          : "N° Treno di ritorno",
      input: "voloRitornoNumero",
      placeholder: "Inserisci numero volo ritorno",
      type: "text",
      value: dettagliMezziTrasporto.voloRitornoNumero,
      displayWithAuto: false,
    },
    {
      id: 2,
      label: "> Orario di arrivo",
      input: "oraArrivoAndata",
      placeholder: "Inserisci ora di arrivo",
      type: "time",
      value: dettagliMezziTrasporto.oraArrivoAndata,
      displayWithAuto: true,
    },
    {
      id: 4,
      label: "> Orario di partenza",
      input: "oraRitornoPartenza",
      placeholder: "Inserisci ora di ritorno partenza",
      type: "time",
      value: dettagliMezziTrasporto.oraRitornoPartenza,
      displayWithAuto: true,
    },
  ];
  return (
    <div
      className="account-pages bg-grey-stellantis"
      style={{
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <div className="container">
        <div
          className="row"
          style={{
            // minHeight: "100vh",
            height: "auto",
          }}
        >
          <div
            className="col-12 p-0"
            style={{ height: "100px", marginBottom: "60px" }}
          >
            <div className="box-settings-sessioni position-relative">
              <Logout />
              <img src={`${dettagliEvento.logoEvento}`} alt="" />
              <div className="container-nome-evento-sessioni">
                <div className="box-nome-evento-sessioni">
                  {dettagliEvento.nomeEvento}
                </div>
                <div className="box-caricamento p-0 bg-white">
                  <div
                    className="progress-bar progress-bar-selezione progress-bar-trasporti"
                    role="progressbar"
                    style={{
                      width: "66.8%",
                    }}
                    aria-valuenow="66.8"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" style={{ marginBottom: "30px" }}>
            <h5
              className="text-center"
              style={{ fontSize: "25px", fontWeight: "bold" }}
            >
              Trasporti
            </h5>
            <p className="text-muted text-center">
              Indicare il mezzo di trasporto usato per arrivare<br></br>e
              l'eventuale richiesta di un transfer.
            </p>
          </div>
          <div className="col-12">
            <div
              className="card bg-blue-stellantis mb-4"
              style={{ borderRadius: "10px" }}
            >
              <div className="card-body">
                <h5 className="card-title text-white  d-flex align-items-start justify-content-between">
                  <b>Mezzo di trasporto</b>
                </h5>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                        style={{ fontSize: "20px" }}
                      >
                        {dettagliMezziTrasporto.mezzoTrasporto === "aereo" ? (
                          <div className="d-flex align-items-center gap-3">
                            {" "}
                            <Plane style={{ fontSize: "25px" }} /> Aereo
                          </div>
                        ) : dettagliMezziTrasporto.mezzoTrasporto ===
                          "treno" ? (
                          <div className="d-flex align-items-center gap-3">
                            {" "}
                            <Train style={{ fontSize: "25px" }} /> Treno
                          </div>
                        ) : dettagliMezziTrasporto.mezzoTrasporto === "auto" ? (
                          <div className="d-flex align-items-center gap-3">
                            {" "}
                            <Car style={{ fontSize: "25px" }} /> Automunito
                          </div>
                        ) : (
                          "Seleziona mezzo"
                        )}
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body ">
                        <button
                          className="btn bg-transparent text-black p-0 d-flex align-items-center gap-3"
                          style={{ fontSize: "20px" }}
                          onClick={() =>
                            handleChange("mezzoTrasporto", "aereo")
                          }
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <Plane style={{ fontSize: "25px" }} /> Aereo
                        </button>
                        <hr></hr>
                        <button
                          className="btn bg-transparent text-black p-0 d-flex align-items-center gap-3"
                          style={{ fontSize: "20px" }}
                          onClick={() =>
                            handleChange("mezzoTrasporto", "treno")
                          }
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <Train style={{ fontSize: "25px" }} />
                          Treno
                        </button>
                        <hr></hr>
                        <button
                          className="btn bg-transparent text-black p-0 d-flex align-items-center gap-3"
                          style={{ fontSize: "20px" }}
                          onClick={() => handleChange("mezzoTrasporto", "auto")}
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <Car style={{ fontSize: "25px" }} />
                          Automunito
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Input Aereo  */}
                <div className="row mt-3">
                  {inputInfoAereo.map((dato, index) => {
                    return (
                      <div
                        className={`col-6 ${
                          !dato.displayWithAuto &&
                          dettagliMezziTrasporto.mezzoTrasporto === "auto"
                            ? "d-none"
                            : ""
                        }`}
                        key={index}
                      >
                        <div className="form-group mb-3" key={dato.id}>
                          <label
                            htmlFor={dato.input}
                            className="text-white"
                            style={{ fontSize: "14px" }}
                          >
                            {dato.label}
                          </label>
                          <input
                            type={dato.type}
                            className="form-control mt-1 border-0"
                            id={dato.input}
                            value={dato.value}
                            placeholder={dato.placeholder}
                            style={{ borderRadius: "6px" }}
                            onChange={(e) =>
                              handleChange(dato.input, e.target.value)
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* Necessario transfer  */}
            {dettagliMezziTrasporto.mezzoTrasporto !== "auto" && (
              <div
                className="card bg-blue-stellantis mb-4"
                style={{ borderRadius: "10px" }}
              >
                <div className="card-body">
                  <h5 className="card-title text-white  d-flex align-items-start justify-content-between">
                    <b>Necessario transfer</b>
                  </h5>
                  <div className="d-flex align-items-center justify-content-between gap-4 pt-2">
                    <button
                      className={`btn bg-grey-stellantis w-100 rounded-pill ${
                        dettagliMezziTrasporto.necessarioTransfer
                          ? "necessario-transfer-active"
                          : ""
                      }`}
                      style={{ fontSize: "20px" }}
                      onClick={(e) => handleChange("necessarioTransfer", true)}
                    >
                      sì
                      {/* necessario-transfer-active */}
                    </button>
                    <button
                      className={`btn bg-grey-stellantis w-100 rounded-pill ${
                        !dettagliMezziTrasporto.necessarioTransfer
                          ? "necessario-transfer-active"
                          : ""
                      }`}
                      style={{ fontSize: "20px" }}
                      onClick={(e) => handleChange("necessarioTransfer", false)}
                    >
                      no
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-12">
            <div className="my-3 text-end d-flex align-items-center justify-content-between">
              <Link
                to={`/sessioni/${idSessione}/caricamento-documenti?eventCode=${eventCode}&autologinCode=${autologinCode}`}
                className="btn bg-blue-stellantis w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                type="submit"
              >
                Indietro
              </Link>
              <button
                // to={"/accomodation"}
                onClick={AggiornaDatiMezziTrasporto}
                className="btn bg-blue-stellantis w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                type="submit"
              >
                Avanti
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MezziTrasporto;
