import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import useAuth from "../UseAuth";
import { StateContext } from "../components/StateContainer";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import OverbookingMessage from "./components/overbooking/OverbookingMessage";
import Logout from "./components/logout/Logout";
function Sessioni() {
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    eventCode,
    autologinCode,
    token,
    dettagliEvento,
  } = useContext(StateContext);

  const [sessioniList, setSessioniList] = useState([]);

  useEffect(() => {
    setPageTitle("Sessioni");
  }, []);

  useEffect(() => {
    console.log(eventCode);
    console.log(autologinCode);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/sessioni/list`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        setSessioniList(response.data.response);
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  }, []);

  return (
    <div
      className="account-pages bg-grey-stellantis"
      style={{
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <div className="container">
        <div
          className="row"
          style={{
            // minHeight: "100vh",
            height: "auto",
          }}
        >
          <div
            className="col-12 p-0"
            style={{ height: "100px", marginBottom: "60px" }}
          >
            <div className="box-settings-sessioni position-relative">
              <Logout />
              <img src={`${dettagliEvento.logoEvento}`} alt="" />
              <div className="container-nome-evento-sessioni">
                <div className="box-nome-evento-sessioni">
                  {dettagliEvento.nomeEvento}
                </div>
                <div className="box-caricamento p-0 bg-white">
                  <div
                    className="progress-bar progress-bar-selezione progress-bar-sessioni"
                    role="progressbar"
                    style={{
                      width: "16.7%",
                    }}
                    aria-valuenow="16.7"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" style={{ marginBottom: "30px" }}>
            <h5
              className="text-center"
              style={{ fontSize: "25px", fontWeight: "bold" }}
            >
              Seleziona sessioni
            </h5>
            <p className="text-muted text-center">
              In questa sezione può verificare i dati inseriti<br></br>ed
              eventualmente modificarli ove possibile.
            </p>
          </div>
          {sessioniList.length === 0 ? (
            <p className="text-center text-muted">
              Non sono ancora state create<br></br>sessioni per l'evento
            </p>
          ) : (
            sessioniList.map((sessione, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="col-6">
                    <div
                      className={`card card-sessioni ${
                        (sessione.nPartecipazioni >= sessione.partecipantiMax &&
                          !sessione.overbooking) ||
                        (sessione.overbooking &&
                          sessione.nOverbooking >= sessione.overbookingMax)
                          ? "text-white bg-secondary"
                          : ""
                      }`}
                    >
                      <div className="card-body">
                        <h5
                          className="card-title"
                          style={{ fontSize: "25px", fontWeight: "bold" }}
                        >
                          {sessione.nome}
                        </h5>
                        <p className="mb-2">
                          <Moment format="DD/MM/YYYY">
                            {sessione.data && sessione.data.date}
                          </Moment>
                        </p>
                        <p className="card-text d-flex justify-content-between align-items-center">
                          <span>Posti</span>
                          <span
                            style={{ fontSize: "35px", fontWeight: "bold" }}
                            className={`${
                              sessione.nPartecipazioni >=
                              sessione.partecipantiMax
                                ? "text-danger"
                                : ""
                            }`}
                          >
                            {sessione.nPartecipazioni}/
                            {sessione.partecipantiMax}
                          </span>
                        </p>
                      </div>
                    </div>
                    {sessione.nPartecipazioni < sessione.partecipantiMax ? (
                      <Link
                        className="stretched stretched-link"
                        to={`/sessioni/${sessione.id}/anagrafica-cliente?eventCode=${eventCode}&autologinCode=${autologinCode}`}
                      />
                    ) : sessione.overbooking &&
                      sessione.nOverbooking < sessione.overbookingMax ? (
                      <>
                        <a
                          className="stretched stretched-link"
                          data-bs-toggle="modal"
                          data-bs-target="#overbookingMessage"
                          style={{ cursor: "pointer" }}
                        />
                        <OverbookingMessage
                          idSessione={sessione.id}
                          autologinCode={autologinCode}
                          eventCode={eventCode}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </React.Fragment>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default Sessioni;
