import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./Partecipante/Login";
import Page404 from "./Page404";

import { useNavigate } from "react-router-dom";
import { StateContext } from "./components/StateContainer";
// import Macchine from "./Tecnico/Macchine/Macchine";
// import DettaglioMacchina from "./Tecnico/Macchine/DettaglioMacchina";
// import UnitaInterne from "./Tecnico/Unita/Unita";
// import NuovoInterventoMacchina from "./Tecnico/Macchine/intervento/NuovoInterventoMacchina";
// import ElencoInterventiMacchina from "./Tecnico/Macchine/intervento/ElencoInterventiMacchina";
// import UnitaDettaglio from "./Tecnico/Unita/UnitaDettaglio";
// import NoteTecnico from "./Tecnico/NoteTecnico";
// import CreaNuovaMacchina from "./Tecnico/Macchine/creazione/CreaNuovaMacchina";
// import ModificaMacchina from "./Tecnico/Macchine/modifica/ModificaMacchina";
// import DettaglioInterventoMacchina from "./Tecnico/Macchine/intervento/DettaglioInterventoMacchina";
// import ElencoLettureMacchina from "./Tecnico/Macchine/lettura/ElencoLettureMacchina";
// import CreaNuovaUnita from "./Tecnico/Unita/creazione/CreaNuovaUnita";
// import ModificaUnita from "./Tecnico/Unita/modifica/ModificaUnita";
// import DettaglioInterventoUnita from "./Tecnico/Unita/intervento/DettaglioInterventoUnita";
// import DettaglioMacchinaCliente from "./Cliente/DettaglioMacchinaCliente";
import Registrazione from "./Partecipante/Registrazione";
import ConfermaPartecipazione from "./Partecipante/ConfermaPartecipazione";
import NonPartecipoPage from "./Partecipante/NonPartecipoPage";
import PartecipoPage from "./Partecipante/PartecipoPage";
import Sessioni from "./Partecipante/Sessioni";
import AnagraficaCliente from "./Partecipante/AnagraficaCliente";
import CaricamentoDocumenti from "./Partecipante/CaricamentoDocumenti";
import MezziTrasporto from "./Partecipante/MezziTrasporto";
import Accomodation from "./Partecipante/Accomodation";
import NoteGenerali from "./Partecipante/NoteGenerali";
import Ringraziamento from "./Partecipante/Ringraziamento";
import DispatchingPage from "./Partecipante/DispatchingPage";
import QrCodePartecipante from "./Partecipante/QrCodePartecipante";
import AnagraficaCompletaIscrizione from "./Partecipante/AnagraficaCompletaIscrizione";
import LoginHostess from "./Hostess/LoginHostess";
import DashboardHostess from "./Hostess/DashboardHostess";
import SessioniHostess from "./Hostess/SessioniHostess";
import AnagraficaPartecipanteHostess from "./Hostess/AnagraficaPartecipanteHostess";
import DimenticatoPassword from "./Partecipante/DimenticatoPassword";
import CreaNuovaPassword from "./Partecipante/CreaNuovaPassword";
// import WebcamOCR from "./components/webcam/WebcamOCR";

const RoutesSite = (url) => {
  const { pageTitle, codiceProgetto, userRoles } = useContext(StateContext);
  const RoutePrivata = ({ url }) => {
    const navigate = useNavigate();

    // Imposta l'URL nel sessionStorage
    sessionStorage.setItem("redirectUrl", window.location.href);

    // Effettua il reindirizzamento
    return <Navigate to="/login" />;
  };
  const isAuthenticated = !!localStorage.getItem("token");

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <Routes>
      {/*<------------------------------------- Routes Generali---------------------------------> */}
      <Route path="/login" element={<Login />} />
      <Route path="/dimenticato-password" element={<DimenticatoPassword />} />
      <Route path="/login-hostess" element={<LoginHostess />} />
      <Route path="/check-user" element={<DispatchingPage />} />
      <Route path="/registrazione" element={<Registrazione />} />
      <Route path="/crea-nuova-password" element={<CreaNuovaPassword />} />
      <Route
        path="/"
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <Login />
          ) : isAuthenticated &&
            userRoles &&
            userRoles.includes("ROLE_HOSTESS") ? (
            <LoginHostess />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/:404"
        element={isAuthenticated ? <Page404 /> : <RoutePrivata url="/:404" />}
      ></Route>

      {/*<------------------------------------- Fine Routes Generali---------------------------------> */}

      {/*<------------------------------------- Routes Tecnico---------------------------------> */}

      {/* <Route
        path='/conferma-partecipazione'
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <ConfermaPartecipazione />
          ) : (
            <Navigate to='/login' />
          )
        }
      /> */}

      <Route
        path="/conferma-partecipazione"
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <ConfermaPartecipazione />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/non-partecipo"
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <NonPartecipoPage />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/partecipo"
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <PartecipoPage />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/sessioni"
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <Sessioni />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/sessioni/:idSessione/anagrafica-cliente"
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <AnagraficaCliente />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/sessioni/:idSessione/caricamento-documenti"
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <CaricamentoDocumenti />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/sessioni/:idSessione/mezzi-trasporto"
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <MezziTrasporto />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/sessioni/:idSessione/accomodation"
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <Accomodation />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/sessioni/:idSessione/note-generali"
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <NoteGenerali />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/ringraziamento"
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <Ringraziamento />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/qr-code"
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <QrCodePartecipante />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/anagrafica-completa-iscrizione"
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <AnagraficaCompletaIscrizione />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      {/* Hostess  */}

      <Route
        path="/dashboard"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <DashboardHostess />
          ) : (
            <Navigate to="/login-hostess" />
          )
        }
      />

      <Route
        path="/sessioni-hostess"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <SessioniHostess />
          ) : (
            <Navigate to="/login-hostess" />
          )
        }
      />

      <Route
        path="/anagrafica-partecipante-hostess/:idPartecipante"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <AnagraficaPartecipanteHostess />
          ) : (
            <Navigate to="/login-hostess" />
          )
        }
      />

      {/* <Route
        path="/uscita/:uscitaId/note-tecnico"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <NoteTecnico />
          ) : (
            <Navigate to="/login" />
          )
        }
      /> */}

      {/* Unità Esterne  */}

      {/* <Route
        path="/uscita/:uscitaId/unita-esterne"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <Macchine />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/uscita/:uscitaId/unita-esterne/crea"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <CreaNuovaMacchina />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/uscita/:uscitaId/unita-esterne/:macchinaId"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <DettaglioMacchina />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/uscita/:uscitaId/unita-esterne/:macchinaId/modifica-macchina"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <ModificaMacchina />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/uscita/:uscitaId/unita-esterne/:macchinaId/nuovo-intervento"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <NuovoInterventoMacchina />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/uscita/:uscitaId/unita-esterne/:macchinaId/elenco-interventi"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <ElencoInterventiMacchina />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/uscita/:uscitaId/unita-esterne/:macchinaId/elenco-interventi/:interventoMacchinaId"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <DettaglioInterventoMacchina />
          ) : (
            <Navigate to="/login" />
          )
        }
      /> */}

      {/* <Route
        path='/uscita/:uscitaId/unita-esterne/:macchinaId/nuova-lettura'
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <NuovaLetturaMacchina />
          ) : (
            <Navigate to='/:404' />
          )
        }
      /> */}

      {/* <Route
        path="/uscita/:uscitaId/unita-esterne/:macchinaId/elenco-letture"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <ElencoLettureMacchina />
          ) : (
            <Navigate to="/login" />
          )
        }
      /> */}
      {/* <Route
        path='/uscita/:uscitaId/unita-esterne/:macchinaId/elenco-letture/:letturaMacchinaId'
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <DettaglioLetturaMacchina />
          ) : (
            <Navigate to='/:404' />
          )
        }
      /> */}

      {/* Unità Interne  */}
      {/* 
      <Route
        path="/uscita/:uscitaId/unita-esterne/:macchinaId/unita-interne/"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <UnitaInterne />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="/uscita/:uscitaId/unita-esterne/:macchinaId/unita-interne/crea"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <CreaNuovaUnita />
          ) : (
            <Navigate to="/login" />
          )
        }
      /> */}

      {/* <Route
        path="/uscita/:uscitaId/unita-esterne/:macchinaId/unita-interne/:unitaId"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <UnitaDettaglio />
          ) : (
            <Navigate to="/login" />
          )
        }
      /> */}

      {/* <Route
        path='/uscita/:uscitaId/unita-esterne/:macchinaId/unita-interne/:unitaId/nuovo-intervento'
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <NuovoInterventoUnita />
          ) : (
            <Navigate to='/:404' />
          )
        }
      />

      <Route
        path='/uscita/:uscitaId/unita-esterne/:macchinaId/unita-interne/:unitaId/elenco-interventi'
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <ElencoInterventiUnita />
          ) : (
            <Navigate to='/:404' />
          )
        }
      /> */}

      {/* <Route
        path="/uscita/:uscitaId/unita-esterne/:macchinaId/unita-interne/:unitaId/modifica-unita"
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <ModificaUnita />
          ) : (
            <Navigate to="/login" />
          )
        }
      /> */}
      {/* 
      <Route
        path='/uscita/:uscitaId/unita-esterne/:macchinaId/unita-interne/:unitaId/elenco-interventi/:interventoUnitaId'
        element={
          isAuthenticated && userRoles && userRoles.includes("ROLE_HOSTESS") ? (
            <DettaglioInterventoUnita />
          ) : (
            <Navigate to='/:404' />
          )
        }
      /> */}

      {/*<------------------------------------- Fine Routes Tecnico---------------------------------> */}

      {/* <Route
        path="/unita-esterna-cliente/:macchinaId"
        element={
          isAuthenticated &&
          userRoles &&
          userRoles.includes("ROLE_PARTECIPANTE") ? (
            <DettaglioMacchinaCliente />
          ) : (
            <Navigate to="/login" />
          )
        }
      /> */}
    </Routes>
  );
};

export default RoutesSite;
