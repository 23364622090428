import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import useAuth from "../UseAuth";
import { SiSessionize as IconSessione } from "react-icons/si";
import { StateContext } from "../components/StateContainer";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import OverbookingMessage from "./components/overbooking/OverbookingMessage";
import Logout from "./components/logout/Logout";
function SessioniHostess() {
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    eventCode,
    autologinCode,
    token,
    dettagliEvento,
  } = useContext(StateContext);

  const [sessioniList, setSessioniList] = useState([]);

  useEffect(() => {
    setPageTitle("Sessioni");
  }, []);

  useEffect(() => {
    console.log(eventCode);
    console.log(autologinCode);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/sessioni/list`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        setSessioniList(response.data.response);
      })
      .catch((error) => {
        if (error.response.status == "401") {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  }, []);

  return (
    <div
      className="account-pages bg-grey-stellantis"
      style={{
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <div className="container">
        <div
          className="row"
          style={{
            // minHeight: "100vh",
            height: "auto",
          }}
        >
          <div
            className="col-12 p-0"
            style={{ height: "100px", marginBottom: "60px" }}
          >
            <div className="box-settings-sessioni position-relative">
              <Logout />
              <img src={`${dettagliEvento.logoEvento}`} alt="" />
              <div className="container-nome-evento-sessioni">
                <div className="box-nome-evento-anagrafica-completa">
                  {dettagliEvento.nomeEvento}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <Link
              to={`/dashboard?eventCode=${eventCode}`}
              className={`bg-blue-stellantis text-decoration-none border-0 d-flex align-items-center justify-content-between w-75 text-white border-0 p-3 w-100 my-4`}
              style={{ borderRadius: "10px" }}
            >
              <IconSessione style={{ fontSize: "30px" }} />{" "}
              <span className="text-center">Vedi tutte le sessioni</span>
              <span></span>
            </Link>
            {/* <Link
              className="btn bg-blue-stellantis text-white p-4 my-4 w-100 text-decoration-none"
              to={`/dashboard?eventCode=${eventCode}`}
              style={{ borderRadius: "15px" }}
            >
              <h4 className="m-0">Vedi tutte le sessioni</h4>
            </Link> */}
          </div>
          {sessioniList.length === 0 ? (
            <p className="text-center text-muted">
              Non sono ancora state create<br></br>sessioni per l'evento
            </p>
          ) : (
            sessioniList.map((sessione, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="col-6">
                    <div
                      className={`card card-sessioni ${
                        sessione.nPartecipazioni >= sessione.partecipantiMax &&
                        !sessione.overbooking
                          ? "text-white bg-secondary"
                          : ""
                      }`}
                    >
                      <div className="card-body">
                        <h5
                          className="card-title"
                          style={{ fontSize: "25px", fontWeight: "bold" }}
                        >
                          {sessione.nome}
                        </h5>

                        <p className="mb-2">
                          {" "}
                          <Moment format="DD/MM/YYYY">
                            {sessione.data.date}
                          </Moment>
                        </p>
                        <p className="card-text d-flex justify-content-between align-items-center">
                          <span> Posti</span>
                          <span
                            style={{ fontSize: "35px", fontWeight: "bold" }}
                          >
                            {sessione.nPartecipazioni}
                          </span>
                        </p>
                      </div>
                    </div>

                    <Link
                      className="stretched stretched-link"
                      to={`/dashboard?eventCode=${eventCode}&session=${sessione.id}`}
                    ></Link>
                  </div>
                </React.Fragment>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default SessioniHostess;
