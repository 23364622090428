import React from "react";
import { FiLogOut as LogoutIcon } from "react-icons/fi";
import useAuth from "../../../UseAuth";
import Cookies from "js-cookie";

const Logout = () => {
  const { logout } = useAuth();

  let modals = document.getElementsByClassName("modal");
  let modalsBackdrop = document.getElementsByClassName("modal-backdrop");
  const ClickToLogout = () => {
    for (let i = 0; i < modals.length; i++) {
      modals[i].classList.remove("show");
    }
    for (let i = 0; i < modalsBackdrop.length; i++) {
      modalsBackdrop[i].classList.remove("show");
    }
    localStorage.removeItem("token");
    Cookies.remove("eventDetails");
    Cookies.remove("userData");
    logout();
  };

  return (
    <>
      <div
        className="logout"
        data-bs-toggle="modal"
        data-bs-target="#logoutModal"
        style={{ cursor: "pointer" }}
      >
        <LogoutIcon />
      </div>

      <div
        className="modal fade"
        id="logoutModal"
        tabIndex="-1"
        aria-labelledby="logoutModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content border-0 overflow-hidden"
            style={{ borderRadius: "10px 10px 10px 10px" }}
          >
            <div className="modal-header border-0 pb-0">
              <h1
                className="modal-title fs-5 text-blue-stellantis"
                id="logoutModal"
              >
                <b className="d-flex align-items-center gap-2">
                  <LogoutIcon /> Logout
                </b>
              </h1>
            </div>
            <div className="modal-body py-0">
              Stai effettuando la logout, vuoi procedere comunque?
            </div>
            <div className="d-flex align-items-center overflow-hidden">
              <button
                type="button"
                className="btn text-black bg-grey-stellantis w-50 rounded-0"
                data-bs-dismiss="modal"
              >
                Annulla
              </button>
              <button
                type="button"
                className="btn text-white w-50 bg-danger rounded-0"
                onClick={ClickToLogout}
              >
                Esci
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logout;
